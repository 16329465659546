import CryptoJS from "crypto-js";

const secretKeyMain = "rmAdminSecretKey";
export const decryptData = (encryptedData) => {
  if (!encryptedData) {
    return null; // Return null if no encrypted data is available
  }

  try {
    // Decrypt data using AES and secret key
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKeyMain);
    const decryptedData = bytes?.toString(CryptoJS.enc.Utf8);

    // If the result is empty, return null to prevent JSON parsing errors
    if (!decryptedData) {
      return null;
    }

    return JSON.parse(decryptedData);
  } catch (error) {
    console.error("Decryption error:", error);
    return null; // Return null if there's a decryption error
  }
};
export function formatFileSize(bytes) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(2));

  return `${formattedSize} ${sizes[i]}`;
}

const secretKey = "yourSecretKey";
export const encryptedData = (dataToEncrypt) => {
  return CryptoJS.AES.encrypt(dataToEncrypt, secretKey).toString();
};
export const decryptedData = (dataToEncrypt) => {
  var bytes = CryptoJS.AES.decrypt(dataToEncrypt, secretKey);
  var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
};

export function removeDuplicatesById(arr) {
  const seenIds = new Set();
  return arr.filter((obj) => {
    if (seenIds.has(obj._id)) {
      return false; // Duplicate _id, filter it out
    }
    seenIds.add(obj._id);
    return true; // Unique _id, keep it
  });
}

export function combineDateAndTime(dateInput, timeInput) {
  // const date = new Date(dateInput);
  // const [hours, minutes] = timeInput?.split(":")?.map(Number);

  // // Assuming the input date is in local time, adjust it to UTC based on local offset
  // // This is crucial if the input time is meant to be treated as local time
  // const localOffset = date?.getTimezoneOffset() * 60000; // Offset in milliseconds
  // const adjustedDate = new Date(date?.getTime() + localOffset); // Adjust to UTC

  // adjustedDate?.setHours(hours, minutes, 0, 0); // Set time, assuming the timeInput is in local time

  // // Convert to ISO string
  // return adjustedDate.toISOString();
  const [hours, minutes] = timeInput?.split(":")?.map(Number);

  dateInput.setHours(hours, minutes, 0, 0);

  const pad = (num) => num?.toString()?.padStart(2, "0");

  const localFormattedDate = `${dateInput?.getFullYear()}-${pad(
    dateInput?.getMonth() + 1
  )}-${pad(dateInput?.getDate())}T${pad(dateInput?.getHours())}:${pad(
    dateInput?.getMinutes()
  )}`;

  return localFormattedDate;
}

export function convertTimeFormat(timeStr) {
  let [hh, mm, ss] = timeStr?.split(":");
  return `${parseInt(hh)}h ${parseInt(mm)}m`;
}
export function convertTimeFormatMin(timeStr) {
  let [hh, mm, ss] = timeStr?.split(":");
  return `${parseInt(mm)} Min.`;
}

export function formatDate(inputDate) {
  const date = new Date(inputDate);

  // Adjust the time zone if needed (e.g., subtracting or adding hours)
  // Here, we'll just subtract 6 hours to match your desired time
  date.setHours(date.getHours() - 6); // Adjust as needed

  // Extract the components of the date
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  // Construct the final formatted date
  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

  return formattedDate;
}
export function getWeekOfMonth(date) {
  const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const startDayOfMonth = startOfMonth.getDay();
  return Math.ceil((date.getDate() + startDayOfMonth) / 7);
}
export function checkImageDimensionsClient(file) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      if (img.width === 576 && img.height === 189) {
        resolve(true);
      } else {
        reject("Image must be exactly W.576 x H.180 pixels");
      }
    };
    img.onerror = () => {
      reject("Error loading image");
    };
    img.src = URL.createObjectURL(file);
  });
}
export const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), secretKeyMain).toString();
};
