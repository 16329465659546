import React, { useState, useEffect } from "react";
import * as CDS from "../../../styles/CourseDetailStyled";
import * as CDD from "../../../styles/CourseDetStyled";
import * as RS from "../../../styles/ReqStyled";
import mediaImage from "../../../assets/images/mediaImage.png";
import downArrow from "../../../assets/images/downArrow.png";
import editDark from "../../../assets/images/editDark.png";
import YearInput from "../../../components/DatePicker";
import axios from "../../../utils/axios";
import { Course } from "../../../utils/api";
import CourseView from "./CourseView";
import { useSelector } from "react-redux";
import { get } from "lodash";
import Modal from "../../../components/Modal/Modal";
import { Formik, Field, Form, FieldArray } from "formik";
import Input from "../../../components/Inputs/InputAdd";
import * as RNS from "../../../styles/RenewStyled";
import * as LS from "../../../styles/LearnerStyled";
import { batchValidator } from "../../../utils/validator";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { setErrorPop, setErrorPopMsg } from "../../../store/action";
import * as SEC from "../../../styles/SectionStyled";
import { PopUp } from "../../../components/PopUp/PopUp";
import successModal from "../../../assets/images/successModal.png";

const ReqAdditional = ({ navigate, theme, dispatch, localState }) => {
  const [isLoading, setIsLoading] = useState(false);
  const courseId = useSelector((state) => state?.courseId);
  const selectLangJson = useSelector((state) => state?.selectLangJson);
  const [isOpen, setIsOpen] = useState(false);
  const [courseDetailData, setCourseDetailData] = useState([]);
  const [feildLoading, setFeildLoading] = useState(false);
  const [showsucess, setShowsucess] = useState(false);
  const [errorObj, setErrorObj] = useState({});
  const selectLang = useSelector((state) => state.selectLang);
  const getCourseContent = async () => {
    setFeildLoading(true);
    let url = `${Course.getCourseContent}details`;
    const formValue = {
      _id: courseId,
      languageId: selectLang,
    };
    try {
      const { data } = await axios.post(url, formValue);
      setCourseDetailData(data.data[0]);
      setFeildLoading(false);
    } catch (error) {
      // alert(error.response.data.message);
      setFeildLoading(false);
      console.log(error.response.data.message);
      if (error.response && error.response.status === 400) {
        dispatch(setErrorPop(true));
        dispatch(setErrorPopMsg(error?.response?.data?.message));
      }
    }
  };

  useEffect(() => {
    getCourseContent();
  }, [courseId,selectLang]);
  const handleClose = (e) => {
    setIsOpen(e);
  };
  const handleSubmit = async (values) => {
    console.log(values);
    try {
      let url = Course.numberOfBatches;
      let formValue = {
        ...values,
      };
      const { data } = await axios.post(url, formValue);
      setShowsucess(true);
      setIsOpen(false);
      setTimeout(() => {
        setShowsucess(false);
      }, 2000);
    } catch (error) {
      console.log(error.response.data.message);
      let errObj = {};
      if (error.response && error.response.status === 400) {
        dispatch(setErrorPop(true));
        dispatch(setErrorPopMsg(error?.response?.data?.message));
      }
      error.response.data.errors?.map((val) => {
        console.log(val.path, "error val");
        if (!errObj[val.path]) {
          errObj[val.path] = val.msg;
        }
      });
      setErrorObj(errObj);
    }
  };

  const skeletonBaseColor = theme ? "#1F242F" : "#EDEEF1";
  const skeletonHighlightColor = theme ? "#1F242F" : "#EDEEF1";
  return (
    <>
      <CDS.DetailContainer>
        {/* <CourseView
          theme={theme}
          courseId={courseId}
          navigate={navigate}
          localState={localState}
        /> */}
        <CDS.DetailCon themeColor={theme}>
          <CDS.DetailInnerCon>
            {feildLoading ? (
              <Skeleton
                width={200}
                baseColor={skeletonBaseColor}
                highlightColor={skeletonHighlightColor}
                height={28}
                style={{
                  borderRadius: "5px",
                  margin: "1rem 0 ",
                }}
              />
            ) : (
              <RS.ReqTitle themeColor={theme}>
                {selectLangJson.additional_licenses}
              </RS.ReqTitle>
            )}

            <CDD.DetContainer>
              {feildLoading ? (
                <div>
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                </div>
              ) : (
                <CDD.DetailCom themeColor={theme}>
                  <p className="name">{selectLangJson.batch_size}</p>
                  <p className="name">{selectLangJson.number_of_batches}</p>
                  <p className="name">
                    {selectLangJson.cost_per_license_for_batch}
                  </p>
                  <p className="name">
                    {selectLangJson.total_count_of_licenses}
                  </p>
                  <p className="name">
                    {selectLangJson.total_cost_of_licenses}
                  </p>
                </CDD.DetailCom>
              )}
              {feildLoading ? (
                <div>
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                </div>
              ) : (
                <CDD.DetailCom themeColor={theme}>
                  <p className="valo">
                    {get(courseDetailData, "batchSize", "-")}
                  </p>
                  <p className="valo">
                    -{" "}
                    <img
                      src={editDark}
                      onClick={() => {
                        setIsOpen(true);
                      }}
                      alt=""
                    />
                  </p>
                  <p className="valo">-</p>
                  <p className="valo">-</p>
                  <p className="valo">-</p>
                </CDD.DetailCom>
              )}

              {/* <CDD.DetailCom>
                <YearInput
                  placeholderText="15/05/2024"
                  label="End Date"
                />
              </CDD.DetailCom> */}
            </CDD.DetContainer>
            <CDD.ButtonGroup themeColor={theme}>
              <CDD.CancelButton
                themeColor={theme}
                onClick={() =>
                  navigate("/client-admin/courses-manage/policy-document")
                }
              >
                {selectLangJson.cancel}
              </CDD.CancelButton>
              <CDD.SaveButton
                cursor={isLoading}
                disabled={isLoading}
                className="buttonload"
                onClick={() => navigate("/client-admin/courses-manage/renew")}
              >
                {isLoading ? (
                  <i class="fa fa-spinner fa-spin"></i>
                ) : (
                  selectLangJson.submit
                )}
              </CDD.SaveButton>
            </CDD.ButtonGroup>
          </CDS.DetailInnerCon>
        </CDS.DetailCon>
        <Modal
          maxWidth={"600px"}
          isOpen={isOpen}
          theme={theme}
          minHeight={"200px"}
          component={
            <RNS.RenewBox themeColor={theme}>
              <Formik
                enableReinitialize
                initialValues={{ value: "", courseId: courseId }}
                validateOnChange
                validate={batchValidator}
                onSubmit={(values) => handleSubmit(values, false)}
              >
                {(formikBag) => (
                  <Form style={{ width: "100%" }}>
                    <LS.InputBoxs style={{ width: "100%" }}>
                      <Field name="value">
                        {({ field, form, meta }) => (
                          <Input
                            {...field}
                            theme={theme}
                            type="number"
                            min={0}
                            placeholder={selectLangJson.number_of_batches}
                            label={selectLangJson.number_of_batches}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            error={
                              meta.touched && meta.error ? meta.error : null
                            }
                            errors={errorObj?.value}
                          />
                        )}
                      </Field>
                    </LS.InputBoxs>
                    <CDD.ButtonGroup
                      style={{ marginTop: 0 }}
                      themeColor={theme}
                    >
                      <CDD.CancelButton
                        themeColor={theme}
                        onClick={() => {
                          setIsOpen(false);
                        }}
                      >
                        {selectLangJson.cancel}
                      </CDD.CancelButton>
                      <CDD.SaveButton
                        cursor={isLoading}
                        disabled={isLoading}
                        className="buttonload"
                        type="submit"
                      >
                        {isLoading ? (
                          <i class="fa fa-spinner fa-spin"></i>
                        ) : (
                          selectLangJson.save_changes
                        )}
                      </CDD.SaveButton>
                    </CDD.ButtonGroup>
                  </Form>
                )}
              </Formik>
            </RNS.RenewBox>
          }
        />
      </CDS.DetailContainer>
      <PopUp
        isOpen={showsucess}
        onClose={handleClose}
        maxWidth="400px"
        title={
          <>
            <SEC.SuccessIconBoxTwo>
              <img src={theme ? successModal : successModal} alt="danger" />
            </SEC.SuccessIconBoxTwo>
          </>
        }
        content={
          <SEC.InnerSection>
            <SEC.TitleTwo themeColor={theme}>
              {selectLangJson.great}!
            </SEC.TitleTwo>

            <SEC.Subtitle themeColor={theme} style={{ textAlign: "center" }}>
              {selectLangJson.your_data_has_been_successfully_saved}.
            </SEC.Subtitle>
            <SEC.GreenContinue
              onClick={() => {
                setShowsucess(false);
              }}
            >
              {selectLangJson.continue}
            </SEC.GreenContinue>
          </SEC.InnerSection>
        }
      />
    </>
  );
};

export default ReqAdditional;
