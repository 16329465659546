import styled from "styled-components/macro";
import {
  commonStyles,
  colorProvider,
  fontProvider,
  fontWeight,
} from "../utils/StylePropertiy";
import { NavLink } from "react-router-dom";

export const HeaderBox = styled.header`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.ai_c};
  border-bottom: ${(props) =>
    props.themeColor
      ? `1px solid ${colorProvider.blackTwo}`
      : commonStyles.com_b};
  background-color: ${(props) =>
    props.themeColor ? colorProvider.darkBlue : colorProvider.white};

  z-index: 9999;
`;
export const NavBar = styled.nav`
  width: ${commonStyles.wid};
  max-width: 1350px;
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_sb};
  align-items: ${commonStyles.ai_c};
  flex-direction: ${commonStyles.fd_row};
  padding: 0.7rem 0;
  /* position: sticky;
  top: 0; */
  @media (max-width: 1400px) {
    padding: 0.7rem 1rem;
  }
`;

export const FirstContainer = styled.div`
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_sb};
  align-items: ${commonStyles.ai_c};
`;
export const MainLogo = styled.div`
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.ai_c};
  margin-right: ${fontProvider.twoThree};
  cursor: ${commonStyles.pointer};

  img {
    height: 40px;
    width: ${commonStyles.wid};
    object-fit: ${commonStyles.ob_con};
  }
`;
export const Options = styled(NavLink)`
  text-decoration: none;
  background-color: ${(props) =>
    props.isActive
      ? props.themeColor
        ? colorProvider.blackFive
        : colorProvider.navBackground
      : colorProvider.trannsp};
  padding: 10px;
  border-radius: ${commonStyles.nav_br};
  font-size: ${fontProvider.zeroEig};
  font-weight: ${(props) =>
    props.isActive ? fontWeight.six : fontWeight.five};
  margin-right: ${fontProvider.zeroFour};
  color: ${(props) =>
    props.isActive
      ? props.themeColor
        ? colorProvider.navLink
        : colorProvider.navLink
      : props.themeColor
      ? colorProvider.greyThree
      : colorProvider.comLink};

  img {
    height: 10px;
    width: 10px;
    object-fit: ${commonStyles.ob_con};
    margin-left: ${fontProvider.zeroFour};
  }
`;
export const OptionsSupport = styled.div`
  text-decoration: none;
  background-color: ${(props) =>
    props.isActive
      ? props.themeColor
        ? colorProvider.blackFive
        : colorProvider.navBackground
      : colorProvider.trannsp};
  padding: 10px;
  border-radius: ${commonStyles.nav_br};
  font-size: ${fontProvider.zeroEig};
  font-weight: ${(props) =>
    props.isActive ? fontWeight.six : fontWeight.five};
  margin-right: ${fontProvider.zeroFour};
  color: ${(props) =>
    props.isActive
      ? props.themeColor
        ? colorProvider.navLink
        : colorProvider.navLink
      : props.themeColor
      ? colorProvider.greyThree
      : colorProvider.comLink};
  cursor: pointer;
  position: relative;
  img {
    height: 10px;
    width: 10px;
    object-fit: ${commonStyles.ob_con};
    margin-left: ${fontProvider.zeroFour};
  }
`;
export const SecondContainer = styled.div`
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_sb};
  align-items: ${commonStyles.ai_c};
  gap: 0.4rem;
  @media (max-width: 780px) {
    width: 100%;
  }
  .resp {
    @media (max-width: 780px) {
      display: none;
    }
  }
`;
export const SupportMenuCon = styled.div`
min-width: 150px;
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #D7DAE0"};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 2rem;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#FFFFFF")};

  p {
    font-weight: 500;
    font-size: 0.8rem;
    color: ${(props) => (props.themeColor ? "#F5F5F6" : "#363A44")};
    padding: 0.7rem 1rem;
    width: 100%;

    :hover {
      background-color: ${(props) =>
        props.themeColor ? "#333741" : "#F6F7F9"};
    }
  }
`;
export const ComIconContainer = styled.div`
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.ai_c};
  /* border-right: ${(props) =>
    props.themeColor
      ? `1px solid ${colorProvider.blackTwo}`
      : commonStyles.navcom_r_b}; */
  /* padding: 0 15px; */
  font-size: ${fontProvider.zeroEig};
  font-weight: ${fontWeight.five};
  cursor: ${commonStyles.pointer};

  span {
    margin: 0 10px;
    color: ${(props) =>
      props.themeColor ? colorProvider.white : colorProvider.greyDark};
  }

  img {
    height: 17px;
    width: 17px;
    object-fit: ${commonStyles.ob_con};
  }
`;
export const ComSupport = styled.div`
  display: flex;
  align-items: center;
`;
export const ComIconContainers = styled.div`
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.ai_c};
  border-right: ${(props) =>
    props.themeColor
      ? `1px solid ${colorProvider.blackTwo}`
      : commonStyles.navcom_r_b};
  padding: 0 15px;
  font-size: ${fontProvider.zeroEig};
  font-weight: ${fontWeight.five};
  cursor: ${commonStyles.pointer};

  span {
    margin: 0 10px;
    color: ${(props) =>
      props.themeColor ? colorProvider.white : colorProvider.greyDark};
  }

  img {
    height: 17px;
    width: 17px;
    object-fit: ${commonStyles.ob_con};
  }
`;
export const ComIconContainersDown = styled.div`
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.ai_c};
  border-right: ${(props) =>
    props.themeColor
      ? `1px solid ${colorProvider.blackTwo}`
      : commonStyles.navcom_r_b};
  padding: 0 15px;
  font-size: ${fontProvider.zeroEig};
  font-weight: ${fontWeight.five};
  cursor: ${commonStyles.pointer};

  img {
    height: 17px;
    width: 17px;
    object-fit: ${commonStyles.ob_con};
  }
`;
export const BorderContainer = styled.div`
  height: 17px;
  width: 1px;
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.ai_c};
  background-color: ${(props) =>
    props.themeColor ? `${colorProvider.blackSix}` : colorProvider.greyOne};
  margin: 0 8px;
`;
export const GoogleContainer = styled.div`
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.ai_c};
  border: ${(props) =>
    props.themeColor
      ? `1px solid ${colorProvider.blackTwo}`
      : commonStyles.com_b};
  padding: 0 15px;
  font-size: ${fontProvider.zeroEig};
  font-weight: ${fontWeight.five};
  cursor: ${commonStyles.pointer};
  border-radius: 4px;
  padding: 7px;
  img {
    height: 22px;
    width: 22px;
    object-fit: ${commonStyles.ob_con};
  }
`;
export const DownLogo = styled.img`
  height: 10px !important;
  width: 10px !important;
`;
export const ProfileImage = styled.div`
  height: 35px;
  width: 35px;
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.ai_c};
  cursor: ${commonStyles.pointer};

  img {
    height: ${commonStyles.het};
    width: ${commonStyles.wid};
    object-fit: ${commonStyles.ob_con};
    cursor: ${commonStyles.pointer};
    border-radius: 50%;
    border: ${(props) =>
      props.themeColor ? "1px solid #333741" : "1px solid #D7DAE0"};
  }
`;
export const ProfileLogoImage = styled.div`
  height: 40px;
  width: 48px;
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.ai_c};
  cursor: ${commonStyles.pointer};

  img {
    height: ${commonStyles.het};
    width: ${commonStyles.wid};
    object-fit: ${commonStyles.ob_con};
    cursor: ${commonStyles.pointer};
  }
`;
export const MedalImage = styled.div`
  height: 38px;
  width: 38px;
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.ai_c};
  border-radius: 50%;
  border: ${(props) =>
    props.themeColor
      ? `1px solid ${colorProvider.blackTwo}`
      : commonStyles.course_bor};
  background-color: ${(props) =>
    props.themeColor ? colorProvider.blackFive : colorProvider.white};

  img {
    height: auto;
    width: 15px;
    object-fit: ${commonStyles.ob_con};
  }
`;
export const CircleBox = styled.div`
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
export const CircleText = styled.p`
  font-size: ${fontProvider.zeroEig};
  font-weight: ${fontWeight.five};
  color: ${(props) =>
    props.themeColor ? colorProvider.whiteThree : colorProvider.greyDark};
  margin-left: 7px;
  /* white-space: nowrap; */
  @media (max-width: 410px) {
    font-size: ${fontProvider.zeroSix};
  }
`;
export const NavContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const NavInner = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 0.5rem 1.8rem;
  position: relative;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#FFFFFF")};

  @media (max-width: 769px) {
    padding: 1.2rem 1rem;
    justify-content: space-between;
  }
`;
export const NavDeleteInner = styled.div`
  width: 100%;
  display: none;
  align-items: center;
  padding: 0.9rem 1rem;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#FFFFFF")};
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;

  @media (max-width: 769px) {
    display: flex;
    justify-content: space-between;
  }
  @media (max-width: 600px) {
    padding: 0.4rem 1rem;
  }
`;
export const SelectBox = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#24262d")};

  @media (max-width: 600px) {
    font-size: 1rem;
  }
`;
export const CrossButton = styled.div`
  cursor: pointer;
  img {
    height: 18px;
    width: 18px;
    object-fit: contain;
  }
  @media (max-width: 600px) {
    img {
      height: 15px;
      width: 15px;
    }
  }
`;
export const SelectIconBox = styled.div`
  cursor: pointer;
  img {
    height: 30px;
    width: 30px;
    object-fit: contain;
  }
  @media (max-width: 600px) {
    img {
      height: 25px;
      width: 25px;
    }
  }
`;
export const NavLeftBox = styled.div`
  display: none;
  align-items: center;
  @media (max-width: 769px) {
    display: flex;
  }
`;
export const NavRightBox = styled.div`
  display: flex;
  align-items: center;
`;
export const BellIcon = styled.div`
  border-right: 1px solid #b3b9c6;
  padding-right: 0.7rem;
  cursor: pointer;
  img {
    height: 17px;
    width: 17px;
    object-fit: contain;
  }
  @media (max-width: 769px) {
    display: none;
  }
`;
export const ThemeIcon = styled.div`
  padding-left: 0.7rem;
  cursor: pointer;
  img {
    height: 17px;
    width: 17px;
    object-fit: contain;
  }
  @media (max-width: 769px) {
    display: none;
  }
`;
export const ThemeIconResp = styled.div`
  padding-left: 0.7rem;
  cursor: pointer;
  display: none;
  img {
    height: 38px;
    width: 38px;
    object-fit: contain;
  }
  @media (max-width: 769px) {
    display: flex;
  }
`;
export const ThemeIconRespTwo = styled.div`
  padding-left: 0.7rem;
  cursor: pointer;
  display: none;
  img {
    height: 17px;
    width: 17px;
    object-fit: contain;
  }
  @media (max-width: 769px) {
    display: flex;
  }
`;
export const Avatar = styled.div`
  padding-left: 0.9rem;
  border-radius: 50%;
  cursor: pointer;
  img {
    height: 32px;
    width: 32px;
    object-fit: contain;
    border-radius: 50%;
  }
  @media (max-width: 769px) {
    display: none;
  }
`;
export const AvatarSidebar = styled.div`
  /* padding-left: 0.9rem; */
  border-radius: 50%;
  cursor: pointer;
  display: none;
  img {
    height: 32px;
    width: 32px;
    object-fit: contain;
    border-radius: 50%;
  }
  @media (max-width: 769px) {
    display: flex;
  }
`;
export const DrawerBox = styled.div`
  display: none;
  padding-left: 0.9rem;
  /* border-radius: 50%; */
  @media (max-width: 769px) {
    display: flex;
  }
  img {
    height: 38px;
    width: 38px;
    object-fit: contain;
    /* border-radius: 50%; */
  }
`;
export const AccountBox = styled.div`
  width: 18%;
  display: flex;
  /* display: ${(props) => (props.isOpen ? "flex" : "none")}; */
  flex-direction: column;
  justify-content: left;
  align-items: center;
  position: absolute;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  right: 0;
  top: 0;
  margin-top: 3.5rem;
  margin-right: 3.5rem;
  box-shadow: 0px 2px 4px -1px #0000000f, 0px 4px 6px -1px #0000001a;
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #edeef1"};
  border-radius: 8px;
  z-index: 9999;
`;
export const ProfileRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #edeef1"};
  padding: 1rem;
`;
export const ProfileBox = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 45px;
    width: 45px;
    object-fit: contain;
  }
`;
export const NameBox = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 0.8rem;
`;
export const NameTitle = styled.div`
  width: 100%;
  font-weight: 600;
  font-size: 1rem;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#363A44")};
`;
export const NameEmail = styled.div`
  width: 100%;
  font-weight: 400;
  font-size: 0.8rem;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#667085")};
  margin-top: 2px;
`;
export const ComMenuBox = styled(NavLink)`
  text-decoration: none;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 500;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#667085")};
  border-bottom: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #edeef1"};
  padding: 0.8rem 1rem;
  cursor: pointer;

  :hover {
    background-color: ${(props) => (props.themeColor ? "#333741" : "#edeef1")};
  }

  img {
    height: 20px;
    width: 20px;
    object-fit: contain;
    margin-right: 0.5rem;
  }
`;
export const ComMenuBoxCon = styled.div`
  text-decoration: none;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 500;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#667085")};
  border-bottom: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #edeef1"};
  padding: 0.8rem 1rem;
  cursor: pointer;

  :hover {
    background-color: ${(props) => (props.themeColor ? "#333741" : "#edeef1")};
  }

  img {
    height: 20px;
    width: 20px;
    object-fit: contain;
    margin-right: 0.5rem;
  }
`;
export const ComMenuBoxNoBorder = styled(NavLink)`
  text-decoration: none;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 500;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#667085")};
  padding: 0.8rem 1rem;
  cursor: pointer;

  :hover {
    background-color: ${(props) => (props.themeColor ? "#333741" : "#edeef1")};
  }

  img {
    height: 20px;
    width: 20px;
    object-fit: contain;
    margin-right: 0.5rem;
  }
`;

// Notification
export const NotificationBox = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  position: absolute;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  right: 0;
  top: 0;
  margin-top: 3.5rem;
  margin-right: 9rem;
  box-shadow: 0px 2px 4px -1px #0000000f, 0px 4px 6px -1px #0000001a;
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #edeef1"};
  border-radius: 8px;
  z-index: 9999;
`;
export const NotiTitleRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
`;
export const NotiTitle = styled.div`
  font-size: 1rem;
  font-weight: 600;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#363A44")};
`;
export const SeeAllText = styled(NavLink)`
  font-size: 0.8rem;
  font-weight: 500;
  text-decoration: none;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#F05D24")};
  cursor: pointer;
`;
export const NotiContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
export const NotificationCard = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-top: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #edeef1"};
  padding: 0.8rem;
  gap: 0.8rem;
`;
export const NotiLogo = styled.div`
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  img {
    height: 35px;
    width: 35px;
    object-fit: contain;
  }
`;
export const NotiContent = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
`;
export const NotiMessage = styled.div`
  width: 100%;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#363A44")};
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 18px;
  margin-bottom: 8px;
`;
export const TimeViewRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 0.8rem;
`;
export const TimeBox = styled.div`
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#8A94A6")};
  font-weight: 400;
  font-size: 0.8rem;
`;
export const ViewBox = styled.div`
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#F05D24")};
  font-weight: 600;
  font-size: 0.8rem;
`;
