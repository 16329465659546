import styled from "styled-components/macro";
import {
  commonStyles,
  colorProvider,
  fontProvider,
  fontWeight,
} from "../utils/StylePropertiy";

export const RenewBox = styled.div`
  width: ${commonStyles.wid};
  min-width: 500px;
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.ai_c};
  flex-direction: ${commonStyles.fd_col};
  background-color: ${(props) => (props.themeColor ? colorProvider.darkBlue : colorProvider.white)};
  border-radius: ${commonStyles.sec_br};
  padding: 2rem 2rem 1.5rem 2rem;
  @media (max-width:768px) {
    min-width: unset;
  }
`;
