import React, { useState, useEffect } from "react";
import { Formik, Field, Form, FieldArray } from "formik";
import * as LS from "../../../styles/LearnerStyled";
import * as CDD from "../../../styles/CourseDetStyled";
import * as CDS from "../../../styles/CourseDetailStyled";
import {
  clientValidator,
  clientCourseValidator,
  learnerClientValidator,
  policyClientValidator,
  ClientBillingValidator,
  URLValidator,
} from "../../../utils/validator";
import darkCheckboxBaseIcon from "../../../assets/images/dark_base.png";
import darkcheckIcon from "../../../assets/images/darkcheckIcon.png";
import checkboxIcon from "../../../assets/images/Checkbox.png";
import checkboxBaseIcon from "../../../assets/images/_Checkbox_base.png";
import Input from "../../../components/Inputs/InputAdd";
import PageSelect from "../../../components/PageSelect";
import Switch from "../../../components/Switch/Switch";
import plsuIcon from "../../../assets/images/plsuIcon.png";
import SampleExcel from "../../../assets/images/Learners.xlsx";
import downloadIcon from "../../../assets/images/downloadIcon.png";
import UploadTrailer from "../../../components/UploadTrailer";
import darkUploadIcon from "../../../assets/images/darkUploadIcon.png";
import upIcon from "../../../assets/images/upIcon.png";
import { combineDateAndTime, formatFileSize } from "../../../utils/Helpers";
import csvIcon from "../../../assets/images/csvIcon.png";
import delIcon from "../../../assets/images/delete.png";
import orangeDownloadIcon from "../../../assets/images/orangeDownloadIcon.png";
import CourseView from "./CourseView";
import { Course } from "../../../utils/api";
import axios from "../../../utils/axios";
import { useSelector, useDispatch } from "react-redux";
import { setErrorPop, setErrorPopMsg } from "../../../store/action";

const AddLearner = ({ theme, navigate, localState, dispatch }) => {
  const [learnerInitialValues, setLearnerInitialValues] = useState({
    data: [
      {
        name: "",
        email: "",
        mobileNumber: "",
        department: "",
        reportingManager: "",
        city: "",
        designation: "",
        subEntity: "",
        activate: false,
      },
    ],
    isAdd: true,
    isUpload: false,
    fileName: "",
    fileSize: "",
    fileUrl: "",
  });

  const courseId = useSelector((state) => state?.courseId);
  const clientId = useSelector((state) => state?.clientId);
  const pageLimit = 10;
  const [isLoadingUpload, setIsLoadingUpload] = useState(false);
  const selectLangJson = useSelector((state) => state?.selectLangJson);
  const [isLoading, setIsLoading] = useState(false);
  const [errorObj, setErrorObj] = useState({});

  const handleExcel = async (e) => {
    let url =
      "https://vc.rainmaker.co.in/api/v2/admin/private/clientLearner/excel/upload/";
    const formData = new FormData();
    formData.append("excelFile", e);
    // console.log(e);
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    try {
      const { data } = await axios.post(url, formData, config);
      // setIsLoadingUpload(false);
      return data;
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  const handleLearner = async (values) => {
    console.log(values, "values");
    let url = Course.learnerAdd;
    setIsLoading(true);
    var formData = {
      data: values?.data?.map((val, ind) => {
        return {
          userName: val.name,
          email: val.email,
          mobileNumber: val.mobileNumber?.toString(),
          mobileNumberCountryCode: "+91",
          department: val.department,
          reportingManager: val.reportingManager,
          city: val.city,
          designation: val.designation,
          subEntity: val.subEntity,
          active: val.activate ? 1 : 0,
          clientId: clientId,
          clientCourseId: courseId,
        };
      }),
    };
    try {
      const { data } = await axios.post(url, formData);
      setIsLoading(false);
      navigate(-1);
    } catch (error) {
      let errObj = {};
      if (error.response && error.response.status === 400) {
        dispatch(setErrorPop(true));
        dispatch(setErrorPopMsg(error?.response?.data?.message));
      }
      error.response.data.errors?.map((val) => {
        if (!errObj[val.path]) {
          errObj[val.path] = val.msg;
        }
      });
      setErrorObj(errObj);
      setIsLoading(false);
    }
  };
  return (
    <CDS.DetailContainer>
      {/* <CourseView theme={theme} localState={localState} /> */}
      <CDS.DetailCon>
        <CDS.DetailInnerCon>
          <LS.LearnerBox>
            <Formik
              enableReinitialize
              initialValues={learnerInitialValues}
              validate={learnerClientValidator}
              validateOnChange
              onSubmit={(values) => handleLearner(values, false)}
            >
              {(formikBag) => (
                <Form style={{ width: "100%" }}>
                  <LS.FormContainer style={{ padding: 0 }} themeColor={theme}>
                    <LS.FormTitle style={{ marginTop: "0" }} themeColor={theme}>
                      {selectLangJson.add_learner}
                    </LS.FormTitle>
                    <LS.FormContainerBorder
                      themeColor={theme}
                      style={{
                        padding: "0.8rem 0.7rem 0.8rem 1rem",
                        marginTop: "1rem",
                        marginBottom: "1rem",
                      }}
                    >
                      <LS.InputBoxContainers
                        style={{
                          justifyContent: "flex-start",
                        }}
                      >
                        <LS.ComFeedbackRowss>
                          <LS.CheckBoxs
                            onClick={() => {
                              formikBag.setFieldValue(`isAdd`, true);
                              formikBag.setFieldValue(`isUpload`, false);
                            }}
                          >
                            <img
                              src={
                                formikBag.values.isAdd
                                  ? theme
                                    ? darkcheckIcon
                                    : checkboxIcon
                                  : theme
                                  ? darkCheckboxBaseIcon
                                  : checkboxBaseIcon
                              }
                              alt=""
                            />
                          </LS.CheckBoxs>
                          <LS.FeedbackOrangeTextUpl themeColor={theme}>
                            {selectLangJson.add_manually}
                          </LS.FeedbackOrangeTextUpl>
                        </LS.ComFeedbackRowss>
                        <LS.ComFeedbackRowss>
                          <LS.CheckBoxs
                            onClick={() => {
                              formikBag.setFieldValue(`isUpload`, true);
                              formikBag.setFieldValue(`isAdd`, false);
                            }}
                          >
                            <img
                              src={
                                formikBag.values.isUpload
                                  ? theme
                                    ? darkcheckIcon
                                    : checkboxIcon
                                  : theme
                                  ? darkCheckboxBaseIcon
                                  : checkboxBaseIcon
                              }
                              alt=""
                            />
                          </LS.CheckBoxs>
                          <LS.FeedbackOrangeTextUpl themeColor={theme}>
                            {selectLangJson.upload}
                          </LS.FeedbackOrangeTextUpl>
                        </LS.ComFeedbackRowss>
                      </LS.InputBoxContainers>
                    </LS.FormContainerBorder>

                    {formikBag.values.isAdd ? (
                      <FieldArray name="data">
                        {({
                          insert,
                          remove: learnerRemove,
                          push: learnerPush,
                        }) => {
                          return (
                            <LS.FormContainer
                              themeColor={theme}
                              style={{ padding: "0", margin: 0 }}
                            >
                              {formikBag.values?.data?.length > 0 &&
                                formikBag.values?.data?.map((val, ind) => {
                                  return (
                                    <>
                                      <LS.FormContainer
                                        themeColor={theme}
                                        style={{ padding: "0" }}
                                      >
                                        <LS.InputBoxContainer>
                                          <LS.InputBoxs wiDth={"49%"}>
                                            <Field name={`data[${ind}].name`}>
                                              {({ field, form, meta }) => (
                                                <Input
                                                  {...field}
                                                  theme={theme}
                                                  placeholder={
                                                    selectLangJson.learner_name
                                                  }
                                                  label={
                                                    selectLangJson.learner_name
                                                  }
                                                  star={true}
                                                  onChange={form.handleChange}
                                                  onBlur={form.handleBlur}
                                                  error={
                                                    meta.touched && meta.error
                                                      ? meta.error
                                                      : null
                                                  }
                                                  erroros={
                                                    errorObj[
                                                      `data[${ind}].userName`
                                                    ]
                                                  }
                                                />
                                              )}
                                            </Field>
                                          </LS.InputBoxs>
                                          <LS.InputBoxs wiDth={"49%"}>
                                            <Field name={`data[${ind}].email`}>
                                              {({ field, form, meta }) => (
                                                <Input
                                                  {...field}
                                                  theme={theme}
                                                  placeholder={
                                                    selectLangJson.learner_email
                                                  }
                                                  label={
                                                    selectLangJson.learner_email
                                                  }
                                                  star={true}
                                                  onChange={form.handleChange}
                                                  onBlur={form.handleBlur}
                                                  error={
                                                    meta.touched && meta.error
                                                      ? meta.error
                                                      : null
                                                  }
                                                  erroros={
                                                    errorObj[
                                                      `data[${ind}].email`
                                                    ]
                                                  }
                                                />
                                              )}
                                            </Field>
                                          </LS.InputBoxs>
                                        </LS.InputBoxContainer>

                                        <LS.InputBoxContainer>
                                          <LS.InputBoxs wiDth={"49%"}>
                                            <Field
                                              name={`data[${ind}].mobileNumber`}
                                            >
                                              {({ field, form, meta }) => (
                                                <Input
                                                  {...field}
                                                  theme={theme}
                                                  placeholder={
                                                    selectLangJson.mobile_number
                                                  }
                                                  label={
                                                    selectLangJson.mobile_number
                                                  }
                                                  icon={true}
                                                  type="number"
                                                  onChange={form.handleChange}
                                                  onBlur={form.handleBlur}
                                                  error={
                                                    meta.touched && meta.error
                                                      ? meta.error
                                                      : null
                                                  }
                                                  erroros={
                                                    errorObj[
                                                      `data[${ind}].mobileNumber`
                                                    ]
                                                  }
                                                />
                                              )}
                                            </Field>
                                          </LS.InputBoxs>
                                          <LS.InputBoxs wiDth={"49%"}>
                                            <Field
                                              name={`data[${ind}].department`}
                                            >
                                              {({ field, form, meta }) => (
                                                <Input
                                                  {...field}
                                                  theme={theme}
                                                  placeholder={
                                                    selectLangJson.department
                                                  }
                                                  label={
                                                    selectLangJson.department
                                                  }
                                                  onChange={form.handleChange}
                                                  onBlur={form.handleBlur}
                                                  error={
                                                    meta.touched && meta.error
                                                      ? meta.error
                                                      : null
                                                  }
                                                  erroros={
                                                    errorObj[
                                                      `data[${ind}].department`
                                                    ]
                                                  }
                                                />
                                              )}
                                            </Field>
                                          </LS.InputBoxs>
                                        </LS.InputBoxContainer>
                                        <LS.InputBoxContainer>
                                          <LS.InputBoxs wiDth={"49%"}>
                                            <Field
                                              name={`data[${ind}].reportingManager`}
                                            >
                                              {({ field, form, meta }) => (
                                                <Input
                                                  {...field}
                                                  theme={theme}
                                                  placeholder={
                                                    selectLangJson.reporting_manager
                                                  }
                                                  label={
                                                    selectLangJson.reporting_manager
                                                  }
                                                  onChange={form.handleChange}
                                                  onBlur={form.handleBlur}
                                                  error={
                                                    meta.touched && meta.error
                                                      ? meta.error
                                                      : null
                                                  }
                                                  erroros={
                                                    errorObj[
                                                      `data[${ind}].reportingManager`
                                                    ]
                                                  }
                                                />
                                              )}
                                            </Field>
                                          </LS.InputBoxs>
                                          <LS.InputBoxs wiDth={"49%"}>
                                            <Field name={`data[${ind}].city`}>
                                              {({ field, form, meta }) => (
                                                <Input
                                                  {...field}
                                                  theme={theme}
                                                  placeholder={
                                                    selectLangJson.enter_city
                                                  }
                                                  label={
                                                    selectLangJson.enter_city
                                                  }
                                                  onChange={form.handleChange}
                                                  onBlur={form.handleBlur}
                                                  error={
                                                    meta.touched && meta.error
                                                      ? meta.error
                                                      : null
                                                  }
                                                  erroros={
                                                    errorObj[
                                                      `data[${ind}].city`
                                                    ]
                                                  }
                                                />
                                              )}
                                            </Field>
                                          </LS.InputBoxs>
                                        </LS.InputBoxContainer>
                                        <LS.InputBoxContainer>
                                          <LS.InputBoxs wiDth={"49%"}>
                                            <Field
                                              name={`data[${ind}].designation`}
                                            >
                                              {({ field, form, meta }) => (
                                                <Input
                                                  {...field}
                                                  theme={theme}
                                                  placeholder={
                                                    selectLangJson.designation
                                                  }
                                                  label={
                                                    selectLangJson.designation
                                                  }
                                                  onChange={form.handleChange}
                                                  onBlur={form.handleBlur}
                                                  error={
                                                    meta.touched && meta.error
                                                      ? meta.error
                                                      : null
                                                  }
                                                  erroros={
                                                    errorObj[
                                                      `data[${ind}].designation`
                                                    ]
                                                  }
                                                />
                                              )}
                                            </Field>
                                          </LS.InputBoxs>
                                          <LS.InputBoxs wiDth={"49%"}>
                                            <Field
                                              name={`data[${ind}].subEntity`}
                                            >
                                              {({ field, form, meta }) => (
                                                <Input
                                                  {...field}
                                                  theme={theme}
                                                  placeholder={
                                                    selectLangJson.sub_entity
                                                  }
                                                  label="Sub Entity"
                                                  onChange={form.handleChange}
                                                  onBlur={form.handleBlur}
                                                  error={
                                                    meta.touched && meta.error
                                                      ? meta.error
                                                      : null
                                                  }
                                                />
                                              )}
                                            </Field>
                                          </LS.InputBoxs>
                                        </LS.InputBoxContainer>

                                        <LS.DefaultBox>
                                          <LS.DefaultText themeColor={theme}>
                                            {selectLangJson.active_inactive} :
                                          </LS.DefaultText>
                                          <LS.DefaultToggle
                                            style={{ marginLeft: "10px" }}
                                          >
                                            <Switch
                                              rounded={true}
                                              isToggled={val.activate}
                                              bgColorChecked="#F6591C"
                                              roundColorChecked="#ffffff"
                                              border={
                                                theme
                                                  ? "1px solid transparent"
                                                  : "1px solid #D7DAE0"
                                              }
                                              bgColorUnChecked={
                                                theme ? "#1F242F" : "#F5F5F6"
                                              }
                                              roundColorUnChecked="#ffffff"
                                              onToggle={() => {
                                                formikBag.setFieldValue(
                                                  `data[${ind}].activate`,
                                                  !val.activate
                                                );
                                                // setIsToggled(!isToggled)
                                              }}
                                            />
                                          </LS.DefaultToggle>
                                        </LS.DefaultBox>
                                      </LS.FormContainer>
                                      {formikBag.values?.data?.length > 1 ? (
                                        <LS.OrangeButtonText
                                          style={{
                                            justifyContent: "right",
                                            margin: 0,
                                          }}
                                          onClick={() => learnerRemove(ind)}
                                        >
                                          {selectLangJson.delete}
                                        </LS.OrangeButtonText>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  );
                                })}
                              <LS.OrangeButtonText
                                onClick={() => {
                                  learnerPush({
                                    name: "",
                                    email: "",
                                    mobileNumber: "",
                                    department: "",
                                    reportingManager: "",
                                    city: "",
                                    designation: "",
                                    subEntity: "",
                                    activate: false,
                                  });
                                }}
                              >
                                <img
                                  src={plsuIcon}
                                  style={{ marginRight: "10px" }}
                                  alt=""
                                />
                                {selectLangJson.add_new}
                              </LS.OrangeButtonText>
                            </LS.FormContainer>
                          );
                        }}
                      </FieldArray>
                    ) : (
                      ""
                    )}
                    {formikBag.values.isUpload ? (
                      <>
                        <LS.FormContainer
                          style={{ padding: 0 }}
                          themeColor={theme}
                        >
                          <LS.RowTitle>
                            <LS.InputLabless themeColor={theme}>
                              {selectLangJson.upload_learners}
                              <span style={{ color: "#F6591C" }}>*</span>
                            </LS.InputLabless>
                            <LS.OrangeTemplate href={SampleExcel} download>
                              <img src={orangeDownloadIcon} alt="" />{" "}
                              {selectLangJson.download_template}
                            </LS.OrangeTemplate>
                          </LS.RowTitle>
                          <UploadTrailer
                            type="file"
                            id="pic"
                            theme={theme}
                            // label="Upload Translations"
                            upIcon={theme ? darkUploadIcon : upIcon}
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            accepTed="text/csv"
                            other="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            others="application/vnd.ms-excel"
                            alertMsg={selectLangJson.no_csv_found}
                            description={selectLangJson?.xls_desc}
                            // star={true}
                            onChange={async (e) => {
                              const file = e.target.files[0];

                              // Function to check if the file is CSV or Excel
                              const isCsvOrExcel = (file) => {
                                return (
                                  file?.type === "text/csv" ||
                                  file?.type ===
                                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                                  file?.type === "application/vnd.ms-excel"
                                );
                              };

                              if (!isCsvOrExcel(file)) {
                                alert(selectLangJson?.no_csv_fount);
                              } else {
                                setIsLoadingUpload(true);
                                formikBag.setFieldValue(`fileName`, file?.name);
                                formikBag.setFieldValue(
                                  `fileSize`,
                                  formatFileSize(file?.size)
                                );
                                formikBag.setFieldValue(`fileUrl`, file);
                              }
                            }}
                            error={
                              formikBag.touched.fileUrl &&
                              formikBag.errors.fileUrl
                                ? formikBag.errors.fileUrl
                                : null
                            }
                          />
                          {formikBag.values.fileName ? (
                            <LS.ButtonRow themeColor={theme} isBorder={true}>
                              <LS.ButtonGroups themeColor={theme}>
                                <CDD.CancelButton
                                  themeColor={theme}
                                  style={{
                                    margin: "0 0.8rem 0 0",
                                  }}
                                  onClick={() => {
                                    formikBag.setFieldValue(`isAdd`, true);
                                    formikBag.setFieldValue(`isUpload`, false);
                                  }}
                                  type="button"
                                >
                                  {selectLangJson.cancel}
                                </CDD.CancelButton>
                                <CDD.SaveButton
                                  style={{
                                    margin: 0,
                                    fontWeight: "600",
                                  }}
                                  className="buttonload"
                                  type="button"
                                  onClick={async () => {
                                    var imageObj = await handleExcel(
                                      formikBag.values.fileUrl
                                    );
                                    console.log(
                                      imageObj,
                                      "imageObj imageObj imageObj"
                                    );
                                    if (imageObj && imageObj.result) {
                                      const exceObj = imageObj?.result?.map(
                                        (val, ind) => {
                                          return {
                                            name: val.name,
                                            email: val.email,
                                            mobileNumber:
                                              val.mobile?.toString(),
                                            department: val.department,
                                            reportingManager:
                                              val.reportingManager,
                                            city: val.city,
                                            designation: val.designation,
                                            subEntity: val.subEntity,
                                            activate:
                                              val.active === 1 ? true : false,
                                          };
                                        }
                                      );
                                      console.log(exceObj, "exceObj  exceObj");
                                      formikBag.setFieldValue("data", exceObj);
                                      formikBag.setFieldValue("isAdd", true);
                                      formikBag.setFieldValue(
                                        "isUpload",
                                        false
                                      );
                                    }
                                  }}
                                >
                                  {selectLangJson.upload}
                                </CDD.SaveButton>
                              </LS.ButtonGroups>
                            </LS.ButtonRow>
                          ) : (
                            ""
                          )}
                          {formikBag.values.fileName ? (
                            <LS.FileRow
                              themeColor={theme}
                              style={{
                                marginTop: "1rem",
                                justifyContent: "space-between",
                              }}
                            >
                              <LS.FileBox
                                themeColor={theme}
                                style={{ width: "97%" }}
                              >
                                <img src={csvIcon} alt="" />
                                <LS.FileContentBox themeColor={theme}>
                                  <div className="head">
                                    {formikBag.values.fileName}
                                  </div>
                                  <div className="desc">
                                    {formikBag.values.fileSize} - 100%{" "}
                                    {selectLangJson.uploaded}
                                  </div>
                                </LS.FileContentBox>
                              </LS.FileBox>
                              {/* <LS.DelIconTwo
                                themeColor={theme}
                                onClick={() => {
                                  
                                }}
                              >
                                <img src={downloadIcon} alt="" />
                              </LS.DelIconTwo> */}
                              <LS.DelIconTwo
                                themeColor={theme}
                                onClick={() => {
                                  formikBag.setFieldValue(`fileUrl`, "");
                                  formikBag.setFieldValue(`fileName`, "");
                                  formikBag.setFieldValue(`fileSize`, "");
                                  const fileInput =
                                    document.getElementById("pic");
                                  if (fileInput) fileInput.value = "";
                                }}
                              >
                                <img src={delIcon} alt="" />
                              </LS.DelIconTwo>
                            </LS.FileRow>
                          ) : (
                            ""
                          )}
                        </LS.FormContainer>
                      </>
                    ) : (
                      ""
                    )}
                    <LS.ButtonGroup style={{ margin: 0 }} themeColor={theme}>
                      <CDD.CancelButton
                        type="button"
                        themeColor={theme}
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        {selectLangJson.cancel}
                      </CDD.CancelButton>
                      <CDD.SaveButton
                        cursor={isLoading}
                        disabled={isLoading}
                        className="buttonload"
                        type="submit"
                      >
                        {isLoading ? (
                          <i class="fa fa-spinner fa-spin"></i>
                        ) : (
                          selectLangJson.save
                        )}
                      </CDD.SaveButton>
                    </LS.ButtonGroup>
                  </LS.FormContainer>
                </Form>
              )}
            </Formik>
          </LS.LearnerBox>
        </CDS.DetailInnerCon>
      </CDS.DetailCon>
    </CDS.DetailContainer>
  );
};

export default AddLearner;
