import styled from "styled-components/macro";
import {
  commonStyles,
  colorProvider,
  fontProvider,
  fontWeight,
} from "../utils/StylePropertiy";

export const DetContainer = styled.section`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_fs};
  align-items: ${commonStyles.jc_fs};
  text-transform: ${commonStyles.cap};
  margin-top: ${fontProvider.zeroTen};
  @media (max-width: 320px) {
    gap: 1rem;
  }
`;
export const DetailCom = styled.div`
  width: 25%;
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  justify-content: ${commonStyles.jc_fs};
  align-items: ${commonStyles.jc_fs};
  line-height: 35px;

  .name {
    font-size: ${fontProvider.zeroEig};
    font-weight: ${fontWeight.five};
    color: ${(props) =>
      props.themeColor ? colorProvider.whiteTwo : colorProvider.numberT};
    text-align: ${commonStyles.jc_l};
  }
  .valo {
    font-size: 0.93rem;
    font-weight: ${fontWeight.five};
    color: ${(props) =>
      props.themeColor ? colorProvider.whiteThree : colorProvider.greyDark};
    text-align: ${commonStyles.jc_l};
    display: ${commonStyles.ds_fx};
    align-items: ${commonStyles.jc_c};

    img {
      height: 20px;
      width: 20px;
      object-fit: ${commonStyles.ob_con};
      margin-left: 10px;
      cursor: ${commonStyles.pointer};
    }
    @media (max-width: 320px) {
      font-size: 0.81rem;
    }
  }

  @media (max-width: 320px) {
    width: 100%;
  }
`;
export const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #d7dae0"};
  gap: 0.7rem;
  margin-top: 1rem;
  /* background-color: #212121; */
`;
export const SaveButton = styled.button`
  background-color: #f6591c;
  border-radius: 5px;
  text-align: center;
  font-weight: 600;
  font-size: 0.8rem;
  color: #ffffff;
  /* width: 100%; */
  outline: none;
  border: none;
  padding: 0.6rem 1rem;
  margin: 1rem 0;
  margin-right: 0.5rem;
  cursor: ${(props) => (props.cursor ? "not-allowed" : "pointer")};

  img {
    height: 12px;
    width: 12px;
    object-fit: contain;
    margin-right: 5px;
  }

  @media (max-width: 375px) {
    font-size: 0.7rem;
  }

  .buttonload {
    background-color: #04aa6d; /* Green background */
    border: none; /* Remove borders */
    color: white; /* White text */
    padding: 12px 16px; /* Some padding */
    font-size: 16px; /* Set a font size */
  }

  i {
    /* margin-left: 0.5rem; */
    font-size: 1.4rem;
  }

  :hover {
    background-color: #417690;
  }
`;
export const CancelButton = styled.button`
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #d7dae0"};
  border-radius: 8px;
  text-align: center;
  font-weight: 400;
  font-size: 0.8rem;
  color: ${(props) => (props.themeColor ? "#ffffff" : "#464c5e")};
  font-weight: 600;
  /* width: 100%; */
  outline: none;
  padding: 0.6rem 1rem;
  margin: 1rem 0;
  margin-right: 0.5rem;
  cursor: ${(props) => (props.cursor ? "not-allowed" : "pointer")};
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};

  /* @media (max-width: 351px) {
    font-size: 1rem;
  } */

  .buttonload {
    background-color: #04aa6d; /* Green background */
    border: none; /* Remove borders */
    color: white; /* White text */
    padding: 12px 16px; /* Some padding */
    font-size: 16px; /* Set a font size */
  }

  i {
    /* margin-left: 0.5rem; */
    font-size: 1.4rem;
  }

  /* :hover {
    background-color: #417690;
  } */
`;
