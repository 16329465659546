import React, { useState } from "react";
import * as SS from "../../styles/SidebarStyled";
import mainLogo from "../../assets/images/rainLogo.png";
import darkLogo from "../../assets/images/rainLogoDark.png";
import crossGrey from "../../assets/images/crossGrey.png";
import { SidebarData } from "./SidebarData";
import logoutLogo from "../../assets/images/logoutIcon.png";
import downArrowSide from "../../assets/images/downArrowSide.png";
import darkArrowSide from "../../assets/images/darkArrowSide.png";
import { setSidebarIsOpens } from "../../store/action";
import { useSelector } from "react-redux";

const Sidebar = ({ theme, navigate, dispatch }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isActive, setIsActive] = useState(null);
  // const [sidebarIsOpen, setSidebarIsOpen] = useState(true);
  const sidebarIsOpens = useSelector((state) => state?.sidebarIsOpen);
  const sidebarToggle = useSelector((state) => state?.isSidebarToggle);
  const [sidebarIsOpen, setSidebarIsOpen] = useState(sidebarIsOpens);
  const toggle = () => setIsOpen(!isOpen);
  const handleConfirm = () => {
    // localStorage.removeItem("rainDataToken");
    // localStorage.removeItem("accessToken");
    // localStorage.clear();
    // dispatch(setUserData(""));
    // navigate("/");
  };
  return (
    <div>
      <SS.SidebarContainerResp
        sidebarIsOpen={sidebarIsOpens}
        themeColor={theme}
      >
        <SS.InnerSidebar themeColor={theme}>
          <SS.LogoListBox>
            <SS.LogoRow style={{ justifyContent: "flex-end" }}>
              <SS.MainLogo style={{ display: "none" }}>
                <img
                  src={theme ? darkLogo : mainLogo}
                  alt=""
                  style={{ display: isOpen ? "block" : "none" }}
                />
              </SS.MainLogo>
              <SS.ArrowLogoResp
                src={crossGrey}
                alt=""
                onClick={() => {
                  dispatch(setSidebarIsOpens(false));
                }}
              />
            </SS.LogoRow>
            {SidebarData?.map((val, ind) => {
              const isSubMenuActive = isActive === val;
              return (
                <SS.MenuBox key={ind}>
                  <SS.MenuRow
                    themeColor={theme}
                    activecolor={val.activeTab?.includes(
                      window.location.pathname
                    )}
                    onClick={() => {
                      if (val.subMenu) {
                        setIsActive(isSubMenuActive ? null : val);
                      } else {
                        setIsActive(null);
                        navigate(val.path);
                        setSidebarIsOpen(false);
                        dispatch(setSidebarIsOpens(false));
                        // dispatch(setTableLength([]));
                      }
                    }}
                  >
                    <div
                      className="row-wise"
                      onClick={() => {
                        if (val.subMenu) {
                          return "";
                        } else {
                          // navigate(val.path);
                        }
                      }}
                    >
                      <img
                        src={
                          val.activeTab?.includes(window.location.pathname)
                            ? val.activeIcon
                            : val.icon
                        }
                        alt=""
                      />
                      {isOpen && val.title}
                    </div>
                    {val.subMenu && (
                      <img
                        className="arrow-img"
                        src={
                          val.activeTab?.includes(window.location.pathname)
                            ? isActive
                              ? darkArrowSide
                              : downArrowSide
                            : downArrowSide
                        }
                        alt=""
                      />
                    )}
                  </SS.MenuRow>
                  {isActive && (
                    <SS.SubMenuBox
                      isActive={isSubMenuActive}
                      themeColor={theme}
                    >
                      {val.subMenu?.map((subItem, subIndex) => (
                        <SS.ActiveSub
                          themeColor={theme}
                          key={subIndex}
                          activecolor={subItem.activePathTab?.includes(
                            window.location.pathname
                          )}
                          onClick={() => {
                            // navigate(subItem.pathName);
                            // dispatch(setTableLength([]));
                            setSidebarIsOpen(false);
                            dispatch(setSidebarIsOpens(false));
                          }}
                        >
                          {subItem.label}
                        </SS.ActiveSub>
                      ))}
                    </SS.SubMenuBox>
                  )}
                </SS.MenuBox>
              );
            })}
          </SS.LogoListBox>
          <SS.LogOutBox onClick={handleConfirm}>
            <img src={logoutLogo} alt="" />
            {isOpen && <span>Log Out</span>}
          </SS.LogOutBox>
        </SS.InnerSidebar>
        <SS.InnerSidebarBlank
        // onClick={() => {
        //   dispatch(setSidebarIsOpens(false));
        // }}
        ></SS.InnerSidebarBlank>
      </SS.SidebarContainerResp>
    </div>
  );
};

export default Sidebar;
