import styled from "styled-components/macro";

export const NoTableBox = styled.div`
  height: 100%;
  width: 100%;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const NoTableBoxTwo = styled.div`
  height: 100%;
  width: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const NoInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const FirstNo = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 1rem;
  img {
    height: 56px;
    width: 56px;
    object-fit: contain;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
      rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  }
`;
export const SecondNo = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "24262D")};
  margin-bottom: 0rem;
  font-weight: 600;
  text-transform: none;
  `;
export const ThirdNo = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
  margin-bottom: 1.4rem;
  margin-top: 10px;
  text-transform: capitalize;
`;
export const ButtonRowNo = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
`;
