import React, { useState, useEffect } from "react";
import { Formik, Field, Form, FieldArray } from "formik";
import * as LS from "../../../styles/LearnerStyled";
import * as CDD from "../../../styles/CourseDetStyled";
import * as CDS from "../../../styles/CourseDetailStyled";
import {
  clientValidator,
  clientCourseValidator,
  learnerClientValidator,
  policyClientValidator,
  ClientBillingValidator,
  URLValidator,
} from "../../../utils/validator";
import darkCheckboxBaseIcon from "../../../assets/images/dark_base.png";
import darkcheckIcon from "../../../assets/images/darkcheckIcon.png";
import checkboxIcon from "../../../assets/images/Checkbox.png";
import checkboxBaseIcon from "../../../assets/images/_Checkbox_base.png";
import Input from "../../../components/Inputs/InputAdd";
import PageSelect from "../../../components/PageSelectTwo";
import SelectLabel from "../../../components/SelectLabel";
import Switch from "../../../components/Switch/Switch";
import plsuIcon from "../../../assets/images/plsuIcon.png";
import SampleExcel from "../../../assets/images/client_learner.xlsx";
import downloadIcon from "../../../assets/images/downloadIcon.png";
import UploadTrailer from "../../../components/UploadTrailer";
import darkUploadIcon from "../../../assets/images/darkUploadIcon.png";
import upIcon from "../../../assets/images/upIcon.png";
import { combineDateAndTime, formatFileSize } from "../../../utils/Helpers";
import docIcon from "../../../assets/images/docIcon.png";
import delIcon from "../../../assets/images/delete.png";
import orangeDownloadIcon from "../../../assets/images/orangeDownloadIcon.png";
import CourseView from "./CourseView";
import { Categorys, Course } from "../../../utils/api";
import axios from "../../../utils/axios";
import UploadTrailerPolicy from "../../../components/UploadTrailerPolicy";
import { UploadFile } from "../../../utils/uploadImage";
import UploadLoader from "../../../components/UploadLoader";
import pdfIcon from "../../../assets/images/pdfIcon.png";
import { useSelector } from "react-redux";
import { get } from "lodash";
import lightEye from "../../../assets/images/lightEye.png";
import ModalPolicy from "../../../components/Modal/ModalPolicy";
import { colorProvider } from "../../../utils/StylePropertiy";
import crossIcon from "../../../assets/images/crossIcon.png";
import PdfView from "./PdfView";
import * as MSPOP from "../../../styles/ModelStyledTwo";
import DocViewer from "./DocView";
import { setErrorPop, setErrorPopMsg } from "../../../store/action";

const AddLearner = ({ navigate, theme, localState, dispatch }) => {
  const [policyInitialValuess, setPolicyInitialValuess] = useState({
    // courseId: "",
    // clientId: "",
    // categories: "",
    title: "",
    downloadStatus: 0,
    acknowledgementslevel: 0,
    documents: [],
    // policyId: "",
    isDefault: false,
  });
  const [departmentList, setDepartmentList] = useState([]);
  const courseId = useSelector((state) => state?.courseId);
  const selectLangJson = useSelector((state) => state?.selectLangJson);
  const [currentPageDepart, setCurrentPageDepart] = useState(1);
  const [uploadProgresses, setUploadProgresses] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [currentPageDesig, setCurrentPageDesig] = useState(1);
  const [isLoadingUpload, setIsLoadingUpload] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [courseList, setCourseList] = useState([]);
  const [errorObj, setErrorObj] = useState({});
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isOpenPolicy, setIsOpenPolicy] = useState(false);
  const [policyUrl, setPolicyUrl] = useState("");
  const [trailerLoadData, setTrailerLoadData] = useState([]);
  const handleMarkAsDefault = (url, formikBag) => {
    const updatedDocuments = formikBag.values.documents.map((doc) => ({
      ...doc,
      isDefault: doc.documentUrl === url,
    }));

    formikBag.setFieldValue("documents", updatedDocuments);
  };
  const getCourse = async (e) => {
    let url = `${Course.getInfo}${courseId}`;
    try {
      const { data } = await axios.get(url);
      if (data?.data) {
        setCourseList(data?.data[0]);
      } else {
        setCourseList([]);
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };
  useEffect(() => {
    if (courseId) {
      getCourse();
    }
  }, [courseId]);

  const handlePolicy = async (values) => {
    console.log(values, "values");
    let url = Course.createPolicy;
    var formData = {
      clientCourseId: courseId,
      downloadStatus: values.downloadStatus ? 1 : 0,
      title: values.title,
      acknowledgementslevel: values.acknowledgementslevel ? 1 : 0,
      documents: values.documents?.map((val) => {
        return {
          documentName: val.documentName,
          documentSize: val.documentSize,
          documentType: val.documentType,
          documentUrl: val.documentUrl,
          isDefault: val.isDefault ? 1 : 0,
        };
      }),
      isDefault: values.isDefault ? 1 : 0,
    };
    values.policyId && (formData["policyId"] = values.policyId);

    console.log(formData, "formData formData");
    try {
      const { data } = await axios.post(url, formData);
      navigate("/client-admin/courses-manage/policy-document");
      setErrorObj({});
    } catch (error) {
      if (error.response && error.response.status === 400) {
        dispatch(setErrorPop(true));
        dispatch(setErrorPopMsg(error?.response?.data?.message));
      }
      setIsLoading(false);
      console.log(error.response.data.message);
      let errObj = {};
      error.response.data.errors?.map((val) => {
        console.log(val.path, "error val");
        if (!errObj[val.path]) {
          errObj[val.path] = val.msg;
        }
      });
      setErrorObj(errObj);
    }
  };
  const isPdfOrDoc = (file) => {
    const validTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    return (
      validTypes?.includes(file.type) || /\.(pdf|docx?|csv)$/i?.test(file.name)
    );
  };

  const handleClosePolicy = (e) => {
    setIsOpenPolicy(e);
    setPolicyUrl("");
  };

  const handleUploadProgress = (index, progress) => {
    setUploadProgresses((currentProgresses) => {
      const newProgresses = [...currentProgresses];
      newProgresses[index] = progress;
      return newProgresses;
    });
  };
  return (
    <>
      <CDS.DetailContainer>
        {/* <CourseView theme={theme} localState={localState} /> */}
        <CDS.DetailCon>
          <CDS.DetailInnerCon>
            <LS.LearnerBox>
              <Formik
                enableReinitialize
                initialValues={policyInitialValuess}
                validate={policyClientValidator}
                validateOnChange
                onSubmit={(values) => handlePolicy(values, false)}
              >
                {(formikBag) => (
                  <Form style={{ width: "100%" }}>
                    <LS.FormContainer style={{ padding: 0 }} themeColor={theme}>
                      <LS.FormTitle style={{ marginTop: 0 }} themeColor={theme}>
                        {selectLangJson.upload_policy_documents}
                      </LS.FormTitle>

                      <LS.InputBoxContainer>
                        <LS.InputBoxs wiDth={"49%"} style={{ zIndex: "999" }}>
                          <Field name="category">
                            {({ field, form }) => (
                              <Input
                                {...field}
                                placeholder={selectLangJson.category}
                                label={selectLangJson.category}
                                value={get(
                                  courseList?.course,
                                  "categorie.title",
                                  ""
                                )}
                                theme={theme}
                                onChange={(e) => {
                                  formikBag.setFieldValue(
                                    "title",
                                    e.target.value
                                  );
                                }}
                                cursorNot={true}
                              />
                            )}
                          </Field>
                        </LS.InputBoxs>
                        <LS.InputBoxs wiDth={"49%"}>
                          <Field name="course">
                            {({ field, form }) => (
                              <Input
                                {...field}
                                placeholder={selectLangJson.course}
                                label={selectLangJson.course}
                                value={get(
                                  courseList,
                                  "course.courseTitle",
                                  ""
                                )}
                                theme={theme}
                                onChange={(e) => {
                                  formikBag.setFieldValue(
                                    "title",
                                    e.target.value
                                  );
                                }}
                                cursorNot={true}
                              />
                            )}
                          </Field>
                        </LS.InputBoxs>
                        <LS.InputBoxs wiDth={"49%"}>
                          <Field name="title">
                            {({ field, form }) => (
                              <Input
                                {...field}
                                placeholder={selectLangJson.document_title}
                                label={selectLangJson.document_title}
                                value={formikBag.values.title}
                                theme={theme}
                                onChange={(e) => {
                                  formikBag.setFieldValue(
                                    "title",
                                    e.target.value
                                  );
                                  setErrorObj({});
                                }}
                                error={
                                  formikBag.touched.title &&
                                  formikBag.errors.title
                                    ? formikBag.errors.title
                                    : null
                                }
                              />
                            )}
                          </Field>
                        </LS.InputBoxs>
                      </LS.InputBoxContainer>
                      <LS.FormContainer
                        style={{ padding: 0 }}
                        themeColor={theme}
                      >
                        {formikBag.values.documents?.length >= 5 ? (
                          ""
                        ) : (
                          <UploadTrailerPolicy
                            type="file"
                            id="pic"
                            label={selectLangJson?.upload_documents}
                            upIcon={theme ? darkUploadIcon : upIcon}
                            optional={true}
                            multiple={true}
                            accept=".pdf, .doc, .docx, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            alertMsg={selectLangJson?.no_found}
                            description={selectLangJson?.files_size}
                            // star={true}

                            onChange={async (e) => {
                              const files = e.target.files;
                              const filesArray = Object.values(files);

                              console.log(filesArray, "filesArray");

                              const sanitizedFiles = filesArray.map((file) => {
                                const sanitizedFileName = file.name.replace(
                                  /[^a-zA-Z0-9_.]+/g,
                                  "_"
                                );
                                return new File([file], sanitizedFileName, {
                                  type: file.type,
                                });
                              });

                              const newTotal =
                                formikBag.values.documents?.length +
                                filesArray.length;

                              // Check if total files exceed the limit
                              if (newTotal > 5) {
                                alert("Select a maximum of 5 files");
                                return;
                              }

                              // Validate file types (PDF, DOC, DOCX)
                              const validFilesArray = filesArray.filter(
                                (file) => isPdfOrDoc(file)
                              );

                              if (
                                validFilesArray?.length !== filesArray?.length
                              ) {
                                alert(
                                  "Only PDF, DOC, and DOCX files are allowed."
                                );
                                return;
                              }

                              // Check if any file exceeds 100 MB
                              const fileExceedsLimit = filesArray?.some(
                                (file) => file.size / 1024 / 1024 > 100
                              );

                              if (fileExceedsLimit) {
                                alert("Each file must be 100 MB or less");
                                return;
                              }
                              setTrailerLoadData(filesArray);
                              setIsLoadingUpload(true);

                              // Start uploading files
                              try {
                                const uploadedFiles = await Promise.all(
                                  sanitizedFiles.map(async (file, index) => {
                                    const uploadedFile = await UploadFile(
                                      file,
                                      (progress) =>
                                        handleUploadProgress(index, progress)
                                    );
                                    return {
                                      documentName: file.name,
                                      documentSize: formatFileSize(file.size),
                                      documentUrl: uploadedFile,
                                      documentType: file.type,
                                      isDefault: false,
                                    };
                                  })
                                );

                                // Update Formik state with new files
                                formikBag.setFieldValue("documents", [
                                  ...formikBag.values.documents,
                                  ...uploadedFiles,
                                ]);

                                console.log("Uploaded files:", uploadedFiles);
                              } catch (error) {
                                console.error("Error uploading files:", error);
                              } finally {
                                setIsLoadingUpload(false);
                                setUploadProgresses([]);
                              }
                            }}
                            error={
                              formikBag.touched.documents &&
                              formikBag.errors.documents
                                ? formikBag.errors.documents
                                : null
                            }
                          />
                        )}
                        {isLoadingUpload
                          ? // <UploadLoader
                            // icon={
                            //   formikBag.values.fileName?.includes(
                            //     ".pdf"
                            //   )
                            //     ? pdfIcon
                            //     : docIcon // assuming you have a default icon for other file types
                            // }
                            //   upPercentage={uploadProgress}
                            //   name={formikBag.values.fileName}
                            //   size={formikBag.values.fileSize}
                            // />
                            trailerLoadData?.map((val, ind) => {
                              return (
                                <UploadLoader
                                  key={ind}
                                  icon={
                                    val.name?.includes(".pdf")
                                      ? pdfIcon
                                      : docIcon
                                  }
                                  upPercentage={uploadProgresses[ind]}
                                  name={val.name}
                                  size={formatFileSize(val.size)}
                                />
                              );
                            })
                          : ""}
                        {formikBag.values?.documents?.length > 0
                          ? formikBag.values?.documents?.map((val, ind) => {
                              return (
                                <LS.FileRow
                                  key={ind}
                                  style={{
                                    marginTop: "1rem",
                                    marginBottom: 0,
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <LS.FileRow key={ind}>
                                    <LS.FileBoxs
                                      widTh={"97%"}
                                      themeColor={theme}
                                    >
                                      <LS.InnerMediaBox>
                                        <img
                                          src={
                                            val.documentName?.includes(".pdf")
                                              ? pdfIcon
                                              : docIcon
                                          }
                                          alt=""
                                        />
                                        <LS.FileContentBox themeColor={theme}>
                                          <div className="head">
                                            {val.documentName}
                                          </div>
                                          <div className="desc">
                                            {val.documentSize} - 100% Uploaded
                                          </div>
                                        </LS.FileContentBox>
                                      </LS.InnerMediaBox>
                                      <LS.ComFeedbackRow
                                        wiDTh={"50%"}
                                        style={{
                                          justifyContent: "end",
                                        }}
                                      >
                                        <LS.FeedbackOrangeText
                                          themeColor={theme}
                                          fontSizes={"0.8rem"}
                                        >
                                          Mark as default
                                        </LS.FeedbackOrangeText>
                                        <LS.CheckBox
                                          activeColor={val.isDefault}
                                          onClick={() =>
                                            handleMarkAsDefault(
                                              val.documentUrl,
                                              formikBag
                                            )
                                          }
                                        >
                                          <img
                                            src={
                                              val.isDefault
                                                ? theme
                                                  ? darkcheckIcon
                                                  : checkboxIcon
                                                : theme
                                                ? darkCheckboxBaseIcon
                                                : checkboxBaseIcon
                                            }
                                            alt=""
                                          />
                                        </LS.CheckBox>
                                      </LS.ComFeedbackRow>
                                    </LS.FileBoxs>
                                  </LS.FileRow>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "10px",
                                    }}
                                  >
                                    <LS.DelIcon
                                      themeColor={theme}
                                      style={{ position: "static" }}
                                      onClick={() => {
                                        const updatedEpisodes =
                                          formikBag?.values?.documents?.filter(
                                            (_, index) => index !== ind
                                          );

                                        // Update the Formik state to reflect the deletion
                                        formikBag.setFieldValue(
                                          `documents`,
                                          updatedEpisodes
                                        );
                                      }}
                                    >
                                      <img src={delIcon} alt="" />
                                    </LS.DelIcon>
                                  </div>
                                </LS.FileRow>
                              );
                            })
                          : ""}
                      </LS.FormContainer>
                      <LS.ButtonGroup themeColor={theme}>
                        <CDD.CancelButton
                          type="button"
                          themeColor={theme}
                          onClick={() => navigate(-1)}
                        >
                          {selectLangJson.cancel}
                        </CDD.CancelButton>
                        <CDD.SaveButton
                          cursor={isLoading}
                          disabled={isLoading}
                          className="buttonload"
                          type="submit"
                        >
                          {isLoading ? (
                            <i class="fa fa-spinner fa-spin"></i>
                          ) : (
                            selectLangJson.save
                          )}
                        </CDD.SaveButton>
                      </LS.ButtonGroup>
                    </LS.FormContainer>
                  </Form>
                )}
              </Formik>
            </LS.LearnerBox>
          </CDS.DetailInnerCon>
        </CDS.DetailCon>
      </CDS.DetailContainer>
      <ModalPolicy
        maxWidth={"1300px"}
        isOpen={isOpenPolicy}
        // isClose={handleClose}
        backColor={colorProvider.darkBlue}
        component={
          <MSPOP.LeaderContainerModal themeColor={theme}>
            <MSPOP.LeaderHeader themeColor={theme}>
              <MSPOP.HeaderBox themeColor={theme}>
                <p className="head-title">{selectLangJson.policy_document}</p>
              </MSPOP.HeaderBox>
              <MSPOP.CrossButton
                onClick={() => {
                  handleClosePolicy();
                }}
              >
                <img src={crossIcon} alt="" />
              </MSPOP.CrossButton>
            </MSPOP.LeaderHeader>
            <MSPOP.ViewCon>
              {policyUrl ? (
                policyUrl?.includes(".pdf") ? (
                  <PdfView policyUrl={policyUrl} />
                ) : (
                  <DocViewer fileUrl={policyUrl} />
                )
              ) : (
                ""
              )}
            </MSPOP.ViewCon>
          </MSPOP.LeaderContainerModal>
        }
      />
    </>
  );
};

export default AddLearner;
