import styled from "styled-components/macro";
import {
  commonStyles,
  colorProvider,
  fontProvider,
  fontWeight,
} from "../utils/StylePropertiy";

export const ModalContainer = styled.div`
  height: ${commonStyles.het};
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.ai_c};
  background-color: ${colorProvider.transColor};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
`;
export const ModalContainerSo = styled.div`
  height: ${commonStyles.het};
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  flex-direction: column;
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.ai_c};
  background-color: ${colorProvider.transColor};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
`;
export const InnerBox = styled.div`
  max-width: ${(props) => props.maxWidth};
  /* min-height: 300px; */
  background-color: ${(props) =>
    props.backColor ? "transparent" : "transparent"};
  border-radius: ${commonStyles.sec_br};
  display: ${commonStyles.ds_fx};
  /* justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.jc_fs}; */
  flex-direction: column;
  /* overflow-y: auto; */
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #D7DAE0"};

  ::-webkit-scrollbar {
    /* width: 6px; */
    /* Adjust the width as needed */
    background-color: white;
  }

  @media (max-width: 769px) {
    width: 90%;
    min-width: auto;
    margin-top: 50px;
    min-height: unset;
  }
`;
export const InnerBoxPolicy = styled.div`
  max-width: ${(props) => props.maxWidth};
  min-height: 300px;
  background-color: ${(props) =>
    props.backColor ? "transparent" : "transparent"};
  border-radius: ${commonStyles.sec_br};
  display: ${commonStyles.ds_fx};
  /* justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.jc_fs}; */
  flex-direction: column;
  overflow-y: hidden;

  ::-webkit-scrollbar {
    /* width: 6px; */
    /* Adjust the width as needed */
    background-color: white;
  }

  @media (max-width: 769px) {
    width: 90%;
    min-width: auto;
  }
`;
export const InnerBoxCalender = styled.div`
  min-width: 500px;
  min-height: 500px;
  background-color: ${(props) =>
    props.backColor ? "transparent" : "transparent"};
  border-radius: ${commonStyles.sec_br};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.jc_fs};
  padding-left: 5rem;
`;
export const InnerBoxSo = styled.div`
  max-width: ${(props) => props.maxWidth};
  min-height: 300px;
  border-radius: ${commonStyles.sec_br};
  display: ${commonStyles.ds_fx};
  /* justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.jc_fs}; */
  flex-direction: column;
  overflow-y: auto;

  ::-webkit-scrollbar {
    /* width: 6px; */
    /* Adjust the width as needed */
    background-color: white;
  }

  @media (max-width: 769px) {
    width: 90%;
    min-width: auto;
  }
`;
export const CrossRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* height: 20px; */
  background-color: ${(props) =>
    props.themeColor ? colorProvider.darkBlue : colorProvider.white};
  padding: 2rem 2rem 0 2rem;

  .cross-con {
    height: 15px;
    width: 15px;
    object-fit: ${commonStyles.ob_con};
    cursor: pointer;
  }
`;
export const CrossRows = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 20px;
  background-color: ${(props) =>
    props.themeColor ? colorProvider.darkBlue : colorProvider.white};
  padding: 1rem;

  .cross-con {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: red;
  }
`;
export const CrossRowSo = styled.div`
  min-width: 840px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 5px;

  img {
    height: 32px !important;
    width: 32px !important;
    object-fit: ${commonStyles.ob_con};
    cursor: pointer;
  }
`;
export const LeaderContainer = styled.div`
  background-color: ${(props) =>
    props.themeColor ? colorProvider.darkBlue : colorProvider.white};
  display: ${commonStyles.ds_fx};
  width: ${commonStyles.wid};
  flex-direction: ${commonStyles.fd_col};
  padding: ${fontProvider.oneTwo};
  border-radius: ${commonStyles.sec_br};
  box-shadow: 0px 1px 2px 0px #0000000d;
`;
export const LeaderHeader = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_sb};
  align-items: ${commonStyles.jc_c};
  border-bottom: ${(props) =>
    props.themeColor
      ? `1px solid ${colorProvider.blackTwo}`
      : commonStyles.com_b};
  padding-bottom: ${fontProvider.zeroEig};
`;
export const CrossButton = styled.div`
  cursor: ${commonStyles.pointer};
  img {
    height: 12px;
    width: 12px;
    object-fit: ${commonStyles.ob_con};
  }
`;
export const HeaderBox = styled.div`
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};

  .head-title {
    font-size: ${fontProvider.oneThree};
    font-weight: ${fontWeight.six};
    text-align: ${commonStyles.jc_l};
    color: ${(props) =>
      props.themeColor ? colorProvider.white : colorProvider.blackCol};
    font-family: EB Garamond;
    @media (max-width: 400px) {
      font-size: ${fontProvider.oneTwo};
    }
  }
  .head-desc {
    font-size: ${fontProvider.zeroEig};
    font-weight: ${fontWeight.five};
    text-align: ${commonStyles.jc_l};
    color: ${(props) =>
      props.themeColor ? colorProvider.greyOne : colorProvider.dateColor};
    @media (max-width: 400px) {
      font-size: ${fontProvider.zeroSev};
    }
  }
`;
