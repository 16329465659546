import React from "react";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { useDispatch } from "react-redux";
import { setIsModal } from "../../store/action";
import { colorProvider, commonStyles } from "../../utils/StylePropertiy";
export const ModalContainer = styled.div`
  height: ${commonStyles.het};
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.ai_c};
  background-color: ${colorProvider.transColor};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
`;
export const InnerBox = styled.div`
  width: ${(props) => (props.widthHo ? "100%" : "auto")};
  max-width: ${(props) => props.maxWidth};
  min-width: 300px;
  min-height: 250px;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#FFFFFF")};
  border-radius: 12px;
  display: ${commonStyles.ds_fx};
  /* justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.jc_fs}; */
  flex-direction: column;
  /* overflow-y: auto; */
  padding: 1rem 2rem;

  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #D7DAE0"};
  ::-webkit-scrollbar {
    /* width: 6px; */
    /* Adjust the width as needed */
    background-color: white;
  }

  @media (max-width: 769px) {
    width: 90%;
    min-width: unset;
    height: 400px;
    overflow-y: auto;
    padding: 0 1rem;
  }
`;
export const CrossRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 20px;
  background-color: white;
  padding: 10px;

  .cross-con {
    height: 12px;
    width: 12px;
    /* background-color: #fe5f57; */
    /* border-radius: 50%; */
    cursor: pointer;
  }

  .title {
  }
`;
export const Modal = ({
  content,
  maxWidth,
  isOpen,
  isClose,
  backColor,
  title,
  minHeight,
}) => {
  const theme = useSelector((state) => state?.isDark);
  const dispatch = useDispatch();
  return (
    <>
      {isOpen ? (
        <ModalContainer>
          <InnerBox
            maxWidth={maxWidth}
            backColor={backColor}
            themeColor={theme}
            minHeight={minHeight}
          >
            {" "}
            {isClose ? (
              <CrossRow
                themeColor={theme}
                title={title ? true : false}
                onClick={() => {
                  isClose(false);
                  dispatch(setIsModal(false));
                }}
              ></CrossRow>
            ) : (
              ""
            )}
            {title}
            {content}
          </InnerBox>
        </ModalContainer>
      ) : (
        ""
      )}
    </>
  );
};
