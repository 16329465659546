import React from "react";
import * as NT from "../../styles/NoTable";
import * as SEC from "../../styles/SectionStyled";
import searchImg from "../../assets/images/dummyImage.png";
import plusIcon from "../../assets/images/plusIcon.png";
import darkFrame from "../../assets/images/darkFrame.png";
import { useSelector } from "react-redux";

const NoTableFoundSearch = ({ title }) => {
  const theme = useSelector((state) => state?.isDark);
  return (
    <NT.NoTableBoxTwo>
      <NT.NoInner>
        <NT.FirstNo>
          <img src={theme ? darkFrame : searchImg} alt="" />
        </NT.FirstNo>
        <NT.SecondNo themeColor={theme}>
          Searched {title} does not exist.
        </NT.SecondNo>
      </NT.NoInner>
      <NT.NoInner style={{ minHeight: "500px" }}></NT.NoInner>
    </NT.NoTableBoxTwo>
  );
};

export default NoTableFoundSearch;
