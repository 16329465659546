import styled from "styled-components/macro";
import {
  commonStyles,
  colorProvider,
  fontProvider,
  fontWeight,
} from "../utils/StylePropertiy";

export const LearnerBox = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.ai_c};
  margin-top: ${fontProvider.two};
`;
export const ComLearner = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  align-items: ${commonStyles.ai_fs};
  border: ${(props) =>
    props.themeColor
      ? `1px solid ${colorProvider.blackTwo}`
      : commonStyles.com_b};
  border-radius: ${commonStyles.sec_br};
  padding: ${fontProvider.oneFour};
  margin-bottom: ${fontProvider.oneSev};
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const FirstLearner = styled.div`
  width: 33.3%;
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  justify-content: ${commonStyles.jc_c};
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }

  .icon-box {
    display: ${commonStyles.ds_fx};
    justify-content: ${commonStyles.jc_c};
    align-items: ${commonStyles.ai_c};
    background-color: ${colorProvider.navBackground};
    background-color: ${(props) =>
      props.themeColor ? "transparent" : colorProvider.navBackground};
    border-radius: ${commonStyles.nav_br};
    padding: 10px;
    cursor: pointer;
  }

  p {
    font-family: "EB Garamond", serif;
    font-size: ${fontProvider.oneThree};
    font-weight: ${fontWeight.five};
    color: ${(props) =>
      props.themeColor ? colorProvider.greyFour : colorProvider.blackCol};

    @media (max-width: 768px) {
      font-size: 1.2rem;
    }
  }

  img {
    height: 15px;
    width: 15px;
    object-fit: ${commonStyles.ob_con};
  }
`;
export const LearnerIconROw = styled.div`
  display: ${commonStyles.ds_fx};
  align-items: ${commonStyles.ai_c};
  gap: 7px;
  margin-top: ${fontProvider.zeroSev};
  @media (max-width: 768px) {
    margin-top: 0;
  }
  i {
    color: ${(props) =>
      props.themeColor ? colorProvider.greyFour : colorProvider.blackCol};
  }
`;
export const ColumnContainer = styled.div`
  width: 61.7%;
  display: ${commonStyles.ds_fx};
  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const ColumnOne = styled.div`
  width: 30%;
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  justify-content: ${commonStyles.jc_c};
  line-height: 33px;

  p {
    font-size: ${fontProvider.zeroEig};
    font-weight: ${fontWeight.five};
    color: ${(props) =>
      props.themeColor ? colorProvider.whiteThree : colorProvider.blackFour};

    @media (max-width: 768px) {
      font-size: 0.7rem;
    }
  }
`;
export const ColumnTwo = styled.div`
  width: calc(100% - 30%);
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  justify-content: ${commonStyles.jc_c};
  line-height: 33px;
  text-transform: none;
  @media (max-width: 768px) {
    flex: 1;
  }

  p {
    font-size: ${fontProvider.zeroNin};
    font-weight: ${fontWeight.five};
    color: ${(props) =>
      props.themeColor ? colorProvider.whiteTwo : colorProvider.greyDark};

@media (max-width: 768px) {
      font-size: 0.8rem;
    }
  }
`;
export const BadgeContainer = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  align-items: ${commonStyles.ai_fs};
  position: relative;

  .com-container {
    display: ${commonStyles.ds_fx};
    justify-content: ${commonStyles.jc_c};
    align-items: ${commonStyles.jc_c};
    background-color: ${(props) =>
      props.themeColor ? colorProvider.darkBlue : colorProvider.navBackground};
    border-radius: 50%;
    /* padding: 5px; */
    border: ${(props) =>
      props.themeColor
        ? `1px solid ${colorProvider.blackTwo}`
        : commonStyles.course_bor};
  }
  img {
    height: 32px;
    width: 32px;
    object-fit: ${commonStyles.ob_con};
  }
`;
// Upload Container
export const ColumnContainerProfile = styled.div`
  width: 76.7%;
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const ProfileRow = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  align-items: ${commonStyles.ai_c};
  gap: 1.5rem;
  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 1rem;
  }
`;
export const ProfileBox = styled.div`
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.ai_c};
  align-items: ${commonStyles.ai_c};
  position: relative;

  .profile-picture {
    height: 100px;
    width: 100px;
    object-fit: ${commonStyles.of_ct};
    border-radius: 50%;
  }
  span {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: center;
  }
  i {
    /* margin-left: 0.5rem; */
    font-size: 1.4rem;
  }
`;
export const DelIcon = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  border: ${(props) =>
    props.themeColor
      ? `2px solid ${colorProvider.blackFive}`
      : `2px solid ${colorProvider.white}`};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.jc_c};
  text-align: ${commonStyles.jc_c};
  background-color: ${(props) =>
    props.themeColor ? colorProvider.darkBlue : colorProvider.navBackground};
  font-size: ${fontProvider.zeroSev};
  font-weight: ${fontWeight.six};
  color: ${colorProvider.navLink};
  padding: 5px;
  cursor: ${commonStyles.pointer};

  img {
    height: 15px;
    width: 15px;
    object-fit: contain;
  }
`;
export const DelIconTwo = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  /* padding: 1.3rem 0; */

  button {
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    margin-right: 5px;
  }
  img {
    height: 18px;
    width: 18px;
    object-fit: contain;
  }

  @media (max-width: 769px) {
    /* flex-direction: column; */
    /* padding: 10px 0; */
  }
`;
export const ProfileContent = styled.div`
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  align-items: ${commonStyles.ai_fs};
  justify-content: ${commonStyles.ai_fs};
  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .div-button {
    border: ${commonStyles.orange_bor};
    border-radius: 100px;
    outline: none;
    text-align: center;
    background-color: ${colorProvider.white};
    font-size: ${fontProvider.zeroEig};
    font-weight: ${fontWeight.five};
    color: ${colorProvider.navLink};
    padding: ${fontProvider.zeroSev} ${fontProvider.zeroTen};
    display: ${commonStyles.ds_fx};
    align-items: ${commonStyles.ai_c};
    justify-content: ${commonStyles.ai_fs};
    cursor: ${commonStyles.pointer};
  }
  img {
    height: 15px;
    width: 15px;
    object-fit: ${commonStyles.ob_con};
    margin-right: 5px;
  }
  p {
    font-size: ${fontProvider.zeroSev};
    font-weight: ${fontWeight.four};
    color: ${(props) =>
      props.themeColor ? colorProvider.greyThree : colorProvider.comLink};
    margin-top: 10px;
  }
`;
export const DetailBox = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  align-items: ${commonStyles.ai_c};
  flex-direction: ${commonStyles.fd_col};
  margin-top: ${fontProvider.zeroTen};
  gap: ${fontProvider.zeroTen};
`;
export const DetailRow = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  align-items: ${commonStyles.ai_c};
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.6rem;
  }
`;
export const LabelBox = styled.div`
  width: 20%;
  display: ${commonStyles.ds_fx};
  align-items: ${commonStyles.ai_c};
  @media (max-width: 768px) {
    width: 100%;
  }

  label {
    font-size: ${fontProvider.zeroEig};
    font-weight: ${fontWeight.five};
    color: ${(props) =>
      props.themeColor ? colorProvider.whiteThree : colorProvider.blackFour};
  }
`;
export const InputBox = styled.div`
  width: 70%;
  display: ${commonStyles.ds_fx};
  align-items: ${commonStyles.ai_c};
`;
export const InputBoxs = styled.div`
  width: 49%;
  display: ${commonStyles.ds_fx};
  align-items: ${commonStyles.ai_c};
  margin-bottom: 1rem;
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 0;
  }
`;
export const InputBoxSo = styled.div`
  width: 50%;
  display: ${commonStyles.ds_fx};
  align-items: ${commonStyles.ai_c};
`;
export const DefaultToggle = styled.div`
  margin-left: 20px;
`;
export const InputBoxContainers = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;
export const ComFeedbackRowssUser = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  margin-right: 1.4rem;
`;
export const CheckBoxs = styled.div`
  cursor: pointer;
  img {
    height: 20px !important;
    width: 20px !important;
    object-fit: contain;
    margin-right: 10px;
  }
`;
export const FeedbackOrangeTextss = styled.p`
  font-size: ${fontProvider.zeroEig};
  font-weight: ${fontWeight.five};
  color: ${(props) =>
    props.themeColor ? colorProvider.greySix : colorProvider.greyDark};
  margin-top: -3px;
`;
export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1rem;

  .draggable-item {
    transition: transform 0.3s ease;
  }
  @media (max-width: 1100px) {
    padding: 0.7rem;
  }
  @media (max-width: 520px) {
    padding: 0.4rem;
  }
`;
export const FormTitle = styled.div`
  width: 100%;
  text-align: left;
  font-size: 1.4rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-weight: 500;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#24262d")};
  font-family: "EB Garamond", serif;
  border-bottom: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #d7dae0"};
  padding-bottom: 1rem;
  text-transform: capitalize;
`;
export const FormContainerBorder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  border-radius: 8px;
  margin-bottom: 1rem;
  padding: 1.5rem;
  background-color: transparent;
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #edeef1"};

  .draggable-item {
    transition: transform 0.3s ease;
  }
  @media (max-width: 1100px) {
    padding: 0.7rem;
  }
  @media (max-width: 520px) {
    padding: 0.4rem;
  }
`;
export const FeedbackOrangeTextUpl = styled.div`
  color: ${(props) => (props.themeColor ? "#ECECED" : "#363A44")};
  font-size: ${(props) => (props.fontSizes ? props.fontSizes : "0.8rem")};
  font-weight: 500;
  text-transform: capitalize;
`;
export const ComFeedbackRowss = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  margin-right: 1.4rem;
  @media (max-width: 769px) {
    flex-direction: column;
  }
`;
export const InputBoxContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 769px) {
    flex-direction: column;
  }
`;
export const DefaultBox = styled.div`
  width: 100%;
  display: flex;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`;
export const DefaultText = styled.div`
  font-size: 0.8rem;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#363a44")};
  font-weight: 600;
`;
export const OrangeButtonText = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#F05D24")};
  font-weight: 600;
  font-size: 0.8rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  cursor: pointer;

  img {
    height: 15px;
    width: 15px;
    object-fit: contain;
  }
`;
export const RowTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const InputLabless = styled.label`
  font-size: 0.8rem;
  font-weight: 500;
  margin-bottom: 0.3rem;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#363a44")};
  margin-top: 0.5rem;
  text-transform: capitalize;

  span {
    font-weight: 400;
  }
  @media (max-width: 351px) {
    font-size: 0.8rem;
  }
`;
export const OrangeTemplate = styled.a`
  color: #f6591c;
  font-size: 0.8rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    height: 14px;
    width: 14px;
    object-fit: contain;
    margin-right: 7px;
  }
`;
export const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${(props) => (props.ceNter ? "center" : "end")};
  border-top: ${(props) =>
    props.isBorder
      ? props.themeColor
        ? "1px solid #333741"
        : "1px solid #d7dae0"
      : "1px solid transparent"};
  padding-top: ${(props) => (props.isBorder ? "1rem" : "0")};
  margin-top: ${(props) => (props.isBorder ? "0.8rem" : "0")};
  align-items: center;
`;
export const ButtonGroups = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* background-color: #212121; */
`;
export const FileRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
`;
export const FileBox = styled.div`
  width: ${(props) => (props.widTh ? props.widTh : "100%")};
  display: flex;
  align-items: flex-start;
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #D7DAE0"};
  border-radius: 8px;
  padding: 0.5rem 1rem;
  overflow: hidden;
  position: relative;

  /* Inner progress bar styling */
  ::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: ${(props) => props.progressMo}%;
    background-color: ${(props) => (props.themeColor ? "#1F242F" : "#F6F7F9")};
    /* background-color: ${(props) => {
      // Set the color based on the progressMo
      if (props.progressMo < 50) {
        return "#F6F7F9"; // red
      } else if (props.progressMo < 75) {
        return "#F6F7F9"; // yellow
      } else {
        return "#F6F7F9"; // green
      }
    }}; */
    z-index: 0;
    transition: width 0.3s ease;
    border-radius: 8px; // To maintain the border radius of the FileBox
  }
  /* Additional content should be above the progress bar */
  > * {
    position: relative;
    z-index: 1;
  }

  img {
    height: 40px;
    width: 40px;
    margin: 0 !important;
    object-fit: contain;
  }
`;
export const FileContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 8px;
  text-transform: capitalize;

  .head {
    font-weight: 500;
    font-size: 0.8rem;
    color: ${(props) => (props.themeColor ? "#CECFD2" : "#363a44")};
    width: 90%;
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 5px;
  }
  .desc {
    font-weight: 400;
    font-size: 0.8rem;
    color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  span {
    color: #17b26a;
  }

  @media (max-width: 532px) {
    .head {
      width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
export const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #d7dae0"};
  gap: 0.7rem;
  margin-top: 1rem;
  /* background-color: #212121; */
`;
export const FileBoxs = styled.div`
  width: ${(props) => (props.widTh ? props.widTh : "100%")};
  display: flex;
  align-items: center;
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #D7DAE0"};
  border-radius: 8px;
  padding: 0.5rem 1rem;
  overflow: hidden;
  position: relative;

  /* Inner progress bar styling */
  ::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: ${(props) => props.progressMo}%;
    background-color: ${(props) => (props.themeColor ? "#1F242F" : "#F6F7F9")};
    // Set the color based on the progressMo
    // if (props.progressMo < 50) {
    //   return props.themeColor ? "#1F242F" : "#F6F7F9"; // red
    // } else if (props.progressMo < 75) {
    //   return props.themeColor ? "#1F242F" : "#F6F7F9"; // yellow
    // } else {
    //   return props.themeColor ? "#1F242F" : "#F6F7F9"; // green
    // }
    /* }}; */
    z-index: 0;
    transition: width 0.3s ease;
    border-radius: 8px; // To maintain the border radius of the FileBox
  }
  /* Additional content should be above the progress bar */
  > * {
    position: relative;
    z-index: 1;
  }

  img {
    height: 40px;
    width: 40px;
    object-fit: contain;
  }

  @media (max-width: 660px) {
    justify-content: space-between;
  }
`;
export const InnerMediaBox = styled.div`
  width: 50%;
  display: flex;
  /* justify-content: space-between; */
  align-items: flex-start;
  @media (max-width: 660px) {
    width: 90%;
  }
`;
export const ComFeedbackRow = styled.div`
  width: ${(props) => (props.wiDTh ? props.wiDTh : "16.6%")};
  display: flex;
  align-items: center;
  @media (max-width: 660px) {
    width: auto;
  }
`;
export const FeedbackOrangeText = styled.div`
  color: ${(props) =>
    props.activecolor ? (props.themeColor ? "#F05D24" : "#f6591c") : "#667085"};
  font-size: ${(props) => (props.fontSizes ? props.fontSizes : "0.8rem")};
  font-weight: 500;
  text-transform: capitalize;

  @media (max-width: 889px) {
    display: none;
  }
`;
export const CheckBox = styled.div`
  cursor: pointer;
  img {
    height: 20px !important;
    width: 20px !important;
    object-fit: contain;
    margin-left: 10px;
  }
`;
export const DelIconProfile = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  border: ${(props) =>
    props.themeColor
      ? `2px solid ${colorProvider.blackFive}`
      : `2px solid ${colorProvider.white}`};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.jc_c};
  text-align: ${commonStyles.jc_c};
  background-color: ${(props) =>
    props.themeColor ? colorProvider.darkBlue : colorProvider.navBackground};
  font-size: ${fontProvider.zeroSev};
  font-weight: ${fontWeight.six};
  color: ${colorProvider.navLink};
  padding: 5px;
  cursor: ${commonStyles.pointer};
  height: 30px;
  width: 30px;

  img {
    height: 15px;
    width: 15px;
    object-fit: contain;
    margin: 0 !important;
  }
`;
