import React, { useState, useEffect } from "react";
import * as CDS from "../../../styles/CourseDetailStyled";
import * as CDD from "../../../styles/CourseDetStyled";
import mediaImage from "../../../assets/images/mediaImage.png";
import downArrow from "../../../assets/images/downArrow.png";
import editDark from "../../../assets/images/editDark.png";
import DatePickerPopup from "../../../components/DatePickerPopup";
import CourseView from "./CourseView";
import axios from "../../../utils/axios";
import { Course } from "../../../utils/api";
import { get } from "lodash";
import { useSelector } from "react-redux";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ModalThree from "../../../components/VideoModal/ModalThree";
import { setErrorPop, setErrorPopMsg } from "../../../store/action";
import * as SEC from "../../../styles/SectionStyled";
import { PopUp } from "../../../components/PopUp/PopUp";
import successModal from "../../../assets/images/successModal.png";
import { formatDate } from "../../../utils/Helpers";

const CourseDetail = ({ navigate, theme, dispatch, localState }) => {
  const [activeId, setActiveId] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [feildLoading, setFeildLoading] = useState(false);
  const [courseDetailData, setCourseDetailData] = useState([]);
  const selectLangJson = useSelector((state) => state?.selectLangJson);
  const courseId = useSelector((state) => state?.courseId);
  const selectLang = useSelector((state) => state.selectLang);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  console.log(courseId, "courseId");
  const [showsucess, setShowsucess] = useState(false);

  const getCourseContent = async () => {
    setFeildLoading(true);
    let url = `${Course.getCourseContent}details`;
    const formValue = {
      _id: courseId,
      languageId: selectLang,
    };
    try {
      const { data } = await axios.post(url, formValue);
      setFeildLoading(false);
      if (data?.data) {
        setCourseDetailData(data.data[0]);
        const dateObject = new Date(data.data[0]?.endDate);
        // const formattedDate = dateObject?.toString();
        setStartDate(dateObject);
      }
    } catch (error) {
      // alert(error.response.data.message);
      setFeildLoading(false);
      console.log(error.response.data.message);
      if (error.response && error.response.status === 400) {
        dispatch(setErrorPop(true));
        dispatch(setErrorPopMsg(error?.response?.data?.message));
      }
    }
  };

  useEffect(() => {
    getCourseContent();
  }, [courseId,selectLang]);
  const handleVideoClose = (e) => {
    setIsOpen(e);
  };
  const skeletonBaseColor = theme ? "#1F242F" : "#EDEEF1";
  const skeletonHighlightColor = theme ? "#1F242F" : "#EDEEF1";

  const handleSubmit = async (e) => {
    try {
      let url = Course.coureActivated;
      let formValue = {
        courseId: courseId,
        languageId: selectLang,
        value: moment(e).format("YYYY-MM-DD"),
      };
      const { data } = await axios.post(url, formValue);
      getCourseContent();
      setShowsucess(true);
      setIsOpen(false);
      setTimeout(() => {
        setShowsucess(false);
      }, 2000);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        dispatch(setErrorPop(true));
        dispatch(setErrorPopMsg(error?.response?.data?.message));
      }
      console.log(error.response.data.message);
    }
  };

  const handleClose = (e) => {
    setIsOpen(false);
  };
  return (
    <>
      <CDS.DetailContainer>
        {/* <CourseView
          theme={theme}
          courseId={courseId}
          navigate={navigate}
          localState={localState}
        /> */}
        <CDS.DetailCon themeColor={theme}>
          <CDS.DetailInnerCon>
            <CDD.DetContainer>
              {feildLoading ? (
                <div>
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                </div>
              ) : (
                <CDD.DetailCom themeColor={theme}>
                  <p className="name">{selectLangJson.category} :</p>
                  <p className="name">{selectLangJson.course} :</p>
                  <p className="name">
                    {selectLangJson.subscription_start_date} :
                  </p>
                  <p className="name">
                    {selectLangJson.subscription_end_date} :
                  </p>
                  <p className="name">
                    {selectLangJson.number_of_licenses_purchased} :
                  </p>
                  <p className="name">{selectLangJson.cost_per_license} :</p>
                  <p className="name">{selectLangJson.batch_size} :</p>
                  <p className="name">
                    {selectLangJson.license_cost_inflation} :
                  </p>
                  <p className="name">
                    {selectLangJson.course_activation_date} :
                  </p>
                  <p className="name">
                    {selectLangJson.course_activation_time} :
                  </p>
                  <p className="name">
                    {selectLangJson.content_languages_opted} :
                  </p>
                  <p className="name">{selectLangJson.batch_purchase} :</p>
                  <p className="name">{selectLangJson.course_activated} :</p>
                  <p className="name">{selectLangJson.course_due_date} :</p>
                </CDD.DetailCom>
              )}
              {feildLoading ? (
                <div>
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                </div>
              ) : (
                <CDD.DetailCom themeColor={theme}>
                  <p className="valo">
                    {get(courseDetailData, "categorie", "-")}
                  </p>
                  <p className="valo">
                    {get(courseDetailData, "courseName", "-")}
                  </p>
                  <p className="valo">
                    {moment(get(courseDetailData, "startDate", "")).format(
                      "DD/MM/YYYY"
                    )}
                  </p>
                  <p className="valo">
                    {moment(get(courseDetailData, "endDate", "-")).format(
                      "DD/MM/YYYY"
                    )}
                  </p>
                  <p className="valo">
                    {get(courseDetailData, "numberOfLicensesPurchased", "-")}
                  </p>
                  <p className="valo">
                    {get(courseDetailData, "costPerLicences", "-")}
                  </p>
                  <p className="valo">
                    {get(courseDetailData, "batchSize", "-")}
                  </p>
                  <p className="valo">
                    {get(courseDetailData, "licencesCostInfiation", "-")}%
                  </p>
                  <p className="valo">
                    {moment(
                      get(courseDetailData, "courseActivationDateAndTime", "-")
                    ).format("DD/MM/YYYY")}
                  </p>
                  <p className="valo">
                    {moment(
                      get(courseDetailData, "courseActivationDateAndTime", "-")
                    ).format("hh:mm A")}
                  </p>
                  <p className="valo">
                    {get(courseDetailData, "languages", [])
                      ?.map((val) => val.title)
                      ?.join(", ")}
                  </p>
                  <p className="valo">
                    {get(courseDetailData, "batchPurchase", "-") === 1
                      ? "Yes"
                      : "No"}
                  </p>
                  <p className="valo">
                    {get(courseDetailData, "courseActivated", "-") === 1
                      ? "Yes"
                      : "No"}
                  </p>

                  <p className="valo">
                    {moment(startDate).format("DD/MM/YYYY")}{" "}
                    <img
                      src={editDark}
                      onClick={() => {
                        setCalendarOpen(true);
                      }}
                      alt=""
                    />
                  </p>
                </CDD.DetailCom>
              )}
              <CDD.DetailCom></CDD.DetailCom>
            </CDD.DetContainer>
            <CDD.ButtonGroup themeColor={theme}>
              <CDD.CancelButton
                themeColor={theme}
                onClick={() =>
                  navigate("/client-admin/courses-manage/courses-content")
                }
                type="button"
              >
                {selectLangJson.previous}
              </CDD.CancelButton>
              <CDD.SaveButton
                cursor={isLoading}
                disabled={isLoading}
                className="buttonload"
                type="button"
                onClick={() => {
                  handleSubmit(startDate);
                }}
              >
                {isLoading ? (
                  <i class="fa fa-spinner fa-spin"></i>
                ) : (
                  selectLangJson.submit
                )}
              </CDD.SaveButton>
            </CDD.ButtonGroup>
          </CDS.DetailInnerCon>
        </CDS.DetailCon>
      </CDS.DetailContainer>
      <ModalThree
        isOpen={calendarOpen}
        isClose={handleVideoClose}
        theme={theme}
        component={
          <DatePickerPopup
            placeholderText="15/05/2024"
            selected={startDate}
            label="End Date"
            theme={theme}
            // minDate={new Date(moment(get(courseDetailData, "startDate", "")))}
            minDate={
              new Date(moment(get(courseDetailData, "startDate", ""))) <
              new Date()
                ? new Date()
                : new Date(moment(get(courseDetailData, "startDate", "")))
            }
            maxDate={new Date(moment(get(courseDetailData, "endDate", "")))}
            onChange={(date) => {
              console.log(date, "date");
              setStartDate(date);
              setCalendarOpen(false); // Close the calendar after selection
            }}
            // onClickOutside={() => setCalendarOpen(false)}
            open={calendarOpen}
          />
        }
      />
      <PopUp
        isOpen={showsucess}
        onClose={handleClose}
        maxWidth="400px"
        title={
          <>
            <SEC.SuccessIconBoxTwo>
              <img src={theme ? successModal : successModal} alt="danger" />
            </SEC.SuccessIconBoxTwo>
          </>
        }
        content={
          <SEC.InnerSection>
            <SEC.TitleTwo themeColor={theme}>
              {selectLangJson.great}!
            </SEC.TitleTwo>

            <SEC.Subtitle themeColor={theme}>
              {selectLangJson.your_data_has_been_successfully_saved}.
            </SEC.Subtitle>
            <SEC.GreenContinue
              onClick={() => {
                setShowsucess(false);
              }}
            >
              {selectLangJson.continue}
            </SEC.GreenContinue>
          </SEC.InnerSection>
        }
      />
    </>
  );
};

export default CourseDetail;
