import React, { useState, useEffect, useContext, useRef } from "react";
import * as CDS from "../../../styles/CourseDetailStyled";
import mediaImage from "../../../assets/images/mediaImage.png";
import downArrow from "../../../assets/images/downArrow.png";
import upArrow from "../../../assets/images/upArrow.png";
import darkUp from "../../../assets/images/darkUp.png";
import darkDown from "../../../assets/images/darkDown.png";
import playButton from "../../../assets/images/playButton.png";
import CourseView from "./CourseView";
import axios from "../../../utils/axios";
import { Course } from "../../../utils/api";
import { get } from "lodash";
import { useSelector } from "react-redux";
import { SocketContext } from "../../../utils/socket";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import VideoModal from "../../../components/VideoModal/Modal";
import VideoJS from "../../../components/Video/VideoMedia";
import {
  setCourseViewId,
  setErrorPop,
  setErrorPopMsg,
} from "../../../store/action";
import { convertTimeFormatMin, encryptData } from "../../../utils/Helpers";

const CourseContent = ({ navigate, theme, decoded, dispatch }) => {
  const [activeId, setActiveId] = useState(false);
  const [activeIdTrailer, setActiveIdTrailer] = useState(false);
  const playerRef = useRef(null);
  const [courseContentData, setCourseContentData] = useState([]);
  const courseId = useSelector((state) => state?.courseId);
  const selectLang = useSelector((state) => state.selectLang);

  const [isOpenSec, setIsOpenSec] = useState(false);
  const selectLangJson = useSelector((state) => state?.selectLangJson);
  const [feildLoading, setFeildLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const socket = useContext(SocketContext);
  const [videoURL, setVideoURL] = useState("");
  const [videoTiming, setVideoTiming] = useState(0);
  const arr = [
    {
      _id: "1",
      episodeName: "test 1",
      fileSize: "5 MB",
      courseTags: ["tiral", "load", "our", "station"],
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
    },
    {
      _id: "2",
      episodeName: "test 2",
      fileSize: "10 MB",
      courseTags: ["new", "tags", "here"],
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
    },
    {
      _id: "3",
      episodeName: "test 3",
      fileSize: "15 MB",
      courseTags: ["another", "set", "of", "tags"],
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
    },
    {
      _id: "4",
      episodeName: "test 4",
      fileSize: "20 MB",
      courseTags: ["more", "tags"],
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
    },
    {
      _id: "5",
      episodeName: "test 5",
      fileSize: "25 MB",
      courseTags: ["tags", "again"],
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
    },
    {
      _id: "6",
      episodeName: "test 6",
      fileSize: "30 MB",
      courseTags: ["and", "more", "tags"],
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
    },
  ];
  const getCourseContent = async () => {
    setFeildLoading(true);
    let url = `${Course.getCourseContent}content`;
    const formValue = {
      _id: courseId,
      languageId: selectLang,
    };
    try {
      const { data } = await axios.post(url, formValue);
      setCourseContentData(data.data[0]);
      dispatch(setCourseViewId(data.data[0]));
      localStorage.setItem("rainCourseViewId", encryptData(data.data[0]));
      // localStorage.setItem("rainCourseViewId", JSON.stringify(data.data[0]));
      setFeildLoading(false);
    } catch (error) {
      setFeildLoading(false);
      // alert(error.response.data.message);
      console.log(error.response.data.message);
      if (error.response && error.response.status === 400) {
        dispatch(setErrorPop(true));
        dispatch(setErrorPopMsg(error?.response?.data?.message));
      }
    }
  };

  useEffect(() => {
    getCourseContent();
  }, [courseId,selectLang]);
  useEffect(() => {
    // if (tokenID) {
    // socket.emit("token_details_update", { token: tokenID });
    socket.on(
      `add_new_epsoide_${courseId}_${courseContentData?.course_baise_infos?.courseId}`,
      (event) => {
        console.log("event data", event);
      }
    );
  }, [socket]);

  const videoJsOptions = {
    autoplay: false,
    controls: {
      fullscreenToggle: false,
      pictureInPictureToggle: false,
    },
    height: 500, // Specify the height
    width: 500, // Specify the width
    loop: true,
    sources: [
      {
        src: videoURL,
        type: "video/mp4",
      },
    ],
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on("waiting", () => {
      VideoJS.log("player is waiting");
    });

    player.on("dispose", () => {
      VideoJS.log("player will dispose");
    });
  };
  const handleVideoClose = (e) => {
    setIsOpen(e);
  };

  const skeletonBaseColor = theme ? "#1F242F" : "#EDEEF1";
  const skeletonHighlightColor = theme ? "#1F242F" : "#EDEEF1";
  return (
    <>
      <CDS.DetailContainer themeColor={theme}>
        {/* <CourseView
          courseId={courseId}
          navigate={navigate}
          theme={theme}
          selectLangJson={selectLangJson}
        /> */}
        <CDS.DetailCon themeColor={theme}>
          <CDS.DetailInnerCon themeColor={theme}>
            <CDS.AboutBox themeColor={theme}>
              {feildLoading ? (
                <Skeleton
                  width={430}
                  baseColor={skeletonBaseColor}
                  highlightColor={skeletonHighlightColor}
                  height={28}
                  style={{ borderRadius: "5px" }}
                />
              ) : (
                <p className="about-title">
                  {selectLangJson.about_this_course} :
                </p>
              )}
              {feildLoading ? (
                <div style={{ width: "100%" }}>
                  <Skeleton
                    width={"!00%"}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{ borderRadius: "5px", margin: "1rem 0" }}
                  />
                </div>
              ) : (
                <p className="about-desc">
                  {get(
                    courseContentData,
                    "course_baise_infos.baiseDescription",
                    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                  )}
                </p>
              )}
            </CDS.AboutBox>
            {feildLoading ? (
              <Skeleton
                width={430}
                baseColor={skeletonBaseColor}
                highlightColor={skeletonHighlightColor}
                height={28}
                style={{ borderRadius: "5px", margin: "1rem 0" }}
              />
            ) : (
              <CDS.EpisodeTitle themeColor={theme}>
                {selectLangJson.trailer}
              </CDS.EpisodeTitle>
            )}

            <CDS.EpisodeContainer>
              {feildLoading ? (
                <div style={{ width: "100%" }}>
                  <Skeleton
                    width={"100%"}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={78}
                    style={{ borderRadius: "5px", marginBottom: "1rem" }}
                  />
                  <Skeleton
                    width={"100%"}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={78}
                    style={{ borderRadius: "5px", marginBottom: "1rem" }}
                  />
                </div>
              ) : (
                get(courseContentData, "course_baise_infos.trailers", [])?.map(
                  (val, ind) => {
                    return (
                      <CDS.VideoRow key={ind} themeColor={theme}>
                        <CDS.FileRow>
                          <CDS.VideoImageBox>
                            <img
                              src={get(
                                courseContentData,
                                "course_baise_infos.thumbnailImage",
                                ""
                              )}
                              alt=""
                            />
                            <img
                              className="play-butttonon"
                              src={playButton}
                              onClick={() => {
                                setVideoURL(val.url);
                                setIsOpen(true);
                              }}
                              alt=""
                            />
                          </CDS.VideoImageBox>
                          <CDS.VideoContent>
                            <CDS.VideoTitleRow>
                              <CDS.VideoTitle themeColor={theme}>
                                <div>{val.title}</div>
                                <button
                                  onClick={() => {
                                    setActiveIdTrailer((currentActiveId) =>
                                      currentActiveId === val._id ? "" : val._id
                                    );
                                  }}
                                >
                                  <img
                                    src={
                                      activeId === val._id
                                        ? theme
                                          ? darkUp
                                          : upArrow
                                        : theme
                                        ? darkDown
                                        : downArrow
                                    }
                                    alt=""
                                  />
                                </button>
                              </CDS.VideoTitle>
                            </CDS.VideoTitleRow>
                            <CDS.MinBox themeColor={theme}>
                              {convertTimeFormatMin(val.videoTime)}
                            </CDS.MinBox>
                          </CDS.VideoContent>
                        </CDS.FileRow>
                        {activeIdTrailer === val._id ? (
                          <CDS.TagsBox style={{ marginTop: "0.5rem" }}>
                            <CDS.TagsTitle themeColor={theme}>
                              {selectLangJson.description}
                            </CDS.TagsTitle>
                            <CDS.DescContent themeColor={theme}>
                              {val.description}
                            </CDS.DescContent>
                          </CDS.TagsBox>
                        ) : (
                          ""
                        )}
                      </CDS.VideoRow>
                    );
                  }
                )
              )}
            </CDS.EpisodeContainer>
            {feildLoading ? (
              <Skeleton
                width={430}
                baseColor={skeletonBaseColor}
                highlightColor={skeletonHighlightColor}
                height={28}
                style={{ borderRadius: "5px", margin: "1rem 0" }}
              />
            ) : (
              <CDS.EpisodeTitle themeColor={theme}>
                {selectLangJson.episodes}
              </CDS.EpisodeTitle>
            )}

            <CDS.EpisodeContainer>
              {/* {get(courseContentData, "episode.courseEpisodes", [])?.map((val, ind) => { */}
              {feildLoading ? (
                <div style={{ width: "100%" }}>
                  <Skeleton
                    width={"100%"}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={78}
                    style={{ borderRadius: "5px", marginBottom: "1rem" }}
                  />
                  <Skeleton
                    width={"100%"}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={78}
                    style={{ borderRadius: "5px", marginBottom: "1rem" }}
                  />
                  <Skeleton
                    width={"100%"}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={78}
                    style={{ borderRadius: "5px", marginBottom: "1rem" }}
                  />
                  <Skeleton
                    width={"100%"}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={78}
                    style={{ borderRadius: "5px", marginBottom: "1rem" }}
                  />
                </div>
              ) : (
                get(courseContentData, "course_episodes", [])?.map(
                  (val, ind) => {
                    return (
                      <CDS.VideoRow key={ind} themeColor={theme}>
                        <CDS.FileRow>
                          <CDS.VideoImageBox>
                            <img
                              src={get(
                                courseContentData,
                                "course_baise_infos.thumbnailImage",
                                ""
                              )}
                              alt=""
                            />
                          </CDS.VideoImageBox>
                          <CDS.VideoContent>
                            <CDS.VideoTitleRow>
                              <CDS.VideoTitle themeColor={theme}>
                                <div>{val.episodeName}</div>
                                <button
                                  onClick={() => {
                                    setActiveId((currentActiveId) =>
                                      currentActiveId === val._id ? "" : val._id
                                    );
                                  }}
                                >
                                  <img
                                    src={
                                      activeId === val._id
                                        ? theme
                                          ? darkUp
                                          : upArrow
                                        : theme
                                        ? darkDown
                                        : downArrow
                                    }
                                    alt=""
                                  />
                                </button>
                              </CDS.VideoTitle>
                            </CDS.VideoTitleRow>
                            <CDS.MinBox themeColor={theme}>
                              {convertTimeFormatMin(val.videoTime)}
                            </CDS.MinBox>
                          </CDS.VideoContent>
                        </CDS.FileRow>
                        {activeId === val._id ? (
                          <CDS.TagsBox>
                            <CDS.TagsTitle themeColor={theme}>
                              {selectLangJson.tags}
                            </CDS.TagsTitle>
                            <CDS.TagsRow>
                              {val?.courseTags?.map((val) => (
                                <CDS.ComTags themeColor={theme}>
                                  {val}
                                </CDS.ComTags>
                              ))}
                            </CDS.TagsRow>
                          </CDS.TagsBox>
                        ) : (
                          ""
                        )}
                        {activeId === val._id ? (
                          <CDS.TagsBox style={{ marginTop: "0.5rem" }}>
                            <CDS.TagsTitle themeColor={theme}>
                              {selectLangJson.description}
                            </CDS.TagsTitle>
                            <CDS.DescContent themeColor={theme}>
                              {val.description}
                            </CDS.DescContent>
                          </CDS.TagsBox>
                        ) : (
                          ""
                        )}
                        {/* {activeId === val._id ? (
                          <CDS.TagsTitle themeColor={theme}>
                            Assessment Quiz
                          </CDS.TagsTitle>
                        ) : (
                          ""
                        )} */}
                      </CDS.VideoRow>
                    );
                  }
                )
              )}
            </CDS.EpisodeContainer>
          </CDS.DetailInnerCon>
        </CDS.DetailCon>
      </CDS.DetailContainer>
      <VideoModal
        isOpen={isOpen}
        isClose={handleVideoClose}
        component={
          <VideoJS
            options={videoJsOptions}
            onReady={handlePlayerReady}
            handleOpen={setIsOpenSec}
            handleTiming={setVideoTiming}
            timing={videoTiming}
            videoURL={videoURL}
          />
        }
      />
    </>
  );
};

export default CourseContent;
