import React, { useEffect, useState } from "react";
import * as CDS from "../../../styles/CourseDetailStyled";
import * as MS from "../../../styles/ManageStyled";
import * as MSPOP from "../../../styles/ModelStyledTwo";
import * as TS from "../../../styles/TemplateStyled";
import greenTick from "../../../assets/images/greenTick.png";
import redCross from "../../../assets/images/redCross.png";
import shareIcon from "../../../assets/images/shareIcon.png";
import plusIcon from "../../../assets/images/plusIcon.png";
import YearInput from "../../../components/DatePicker";
import searchIcon from "../../../assets/images/lightSearchIcon.png";
import lightDownload from "../../../assets/images/lightDownload.png";
import CourseView from "./CourseView";
import Select from "../../../components/Select";
import darkCheckboxBaseIcon from "../../../assets/images/dark_base.png";
import darkcheckIcon from "../../../assets/images/darkcheckIcon.png";
import checkboxIcon from "../../../assets/images/Checkbox.png";
import successModal from "../../../assets/images/successModal.png";
import checkboxBaseIcon from "../../../assets/images/_Checkbox_base.png";
import { useSelector, useDispatch } from "react-redux";
import {
  setErrorPop,
  setErrorPopMsg,
  setTableLength,
} from "../../../store/action";
import * as SEC from "../../../styles/SectionStyled";
import { Table } from "antd";
import * as CS from "../../../styles/CommonStyled";
import editIcon from "../../../assets/images/editLight.png";
import darkArrow from "../../../assets/images/darkArrow.png";
import darkRight from "../../../assets/images/darkRight.png";
import lighRight from "../../../assets/images/lighRight.png";
import lightLeft from "../../../assets/images/lightLeft.png";
import moment from "moment";
import dotImage from "../../../assets/images/dotImage.png";
import delIcon from "../../../assets/images/delete.png";
import binTable from "../../../assets/images/binTable.png";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import bookIcon from "../../../assets/images/bookIcon.png";
import Switch from "../../../components/Switch/Switch";
import darkDownload from "../../../assets/images/darkDownload.png";
import crossIcon from "../../../assets/images/crossIcon.png";
import lightEye from "../../../assets/images/lightEye.png";
import { Course } from "../../../utils/api";
import axios from "../../../utils/axios";
import { removeDuplicatesById } from "../../../utils/Helpers";
import { PopUp } from "../../../components/PopUp/PopUp";
import { colorProvider } from "../../../utils/StylePropertiy";
import PdfView from "./PdfView";
import DocViewer from "./DocView";
import Modal from "../../../components/Modal/Modal";
import ModalPolicy from "../../../components/Modal/ModalPolicy";
import warnIcon from "../../../assets/images/warn.png";
import NoTableFoundSearch from "../../../components/Template/NoTableFoundSearch";
import { ModalSuccess } from "../../../components/Modal/ModalSuccess";
import darkLowIcon from "../../../assets/images/darkLowIcon.png";

const statusOptions = [
  {
    label: "All",
    value: "All",
  },
  {
    label: "Completed",
    value: "Completed",
  },
  {
    label: "In progress",
    value: "In progress",
  },
  {
    label: "Not Started",
    value: "Not Started",
  },
];
const activeOptions = [
  {
    label: "Active",
    value: "Active",
  },
  {
    label: "Active",
    value: "Active",
  },
  {
    label: "All",
    value: "All",
  },
];
const CourseDetail = ({ navigate, theme, courseId }) => {
  const dispatch = useDispatch();
  const [activeId, setActiveId] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const deleteLengthNum = useSelector((state) => state?.deleteLength);
  const [selectedRowKeys, setSelectedRowKeys] = useState(deleteLengthNum);
  const selectLangJson = useSelector((state) => state?.selectLangJson);
  const [currentPageLearner, setCurrentPageLearner] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [totalCountLearner, setTotalCountLearner] = useState(0);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [opens, setOpens] = useState(false);
  const [listId, setListId] = useState("");
  const [feildLoading, setFeildLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [isOpenPolicy, setIsOpenPolicy] = useState(false);
  const [isOpenPolicys, setIsOpenPolicys] = useState(false);
  const [policyStatus, setpolicyStatus] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [policyUrl, setPolicyUrl] = useState("");
  const pageLimitLearner = 10;
  const policyColumns = [
    {
      title: selectLangJson?.document_title,
      dataIndex: "title",
      key: "title",
      ellipsis: true,
      render: (text, record) => (
        <div style={{ fontWeight: "500" }}>{record.title}</div>
      ),
    },
    {
      title: selectLangJson?.policy_download,
      dataIndex: "policyDownload",
      key: "policyDownload",
      align: "center",
      ellipsis: true,
      render: (text, record) => (
        <div>
          <CS.ComButton>
            <img src={record?.downloadStatus ? greenTick : redCross} alt="" />
          </CS.ComButton>
        </div>
      ),
    },
    {
      title: selectLangJson?.acknowledgement_level,
      dataIndex: "isDefault",
      key: "isDefault",
      align: "center",
      ellipsis: true,
      render: (text, record) => (
        <div>
          <CS.ComButton>
            <img
              src={record?.acknowledgementslevel ? greenTick : redCross}
              alt=""
            />
          </CS.ComButton>
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "isActive",
      key: "isActive",
      width: 200,
      ellipsis: true,
      render: (text, record) => (
        <div style={{ display: "flex", gap: "1rem" }}>
          <CS.ComButtonClient
            onClick={() => {
              setOpens(true);
              setListId(record._id);
            }}
            isCursor={record._id}
          >
            <img src={delIcon} alt="" />
          </CS.ComButtonClient>

          {/* <CS.ComButtonClient>
            <img src={editIcon} alt="" />
          </CS.ComButtonClient> */}
          <CS.ComButtonClient
            onClick={() => {
              setIsOpenPolicy(true);
              setPolicyUrl(record?.documents?.documentUrl);
            }}
            isCursor={record?.documents?.documentUrl}
          >
            <img src={lightEye} alt="" />
          </CS.ComButtonClient>
          <CS.ComButtonClient
            onClick={() => {
              if (record?.downloadStatus) {
                window.location.href = record?.documents?.documentUrl;
              }
            }}
            isCursor={record?.downloadStatus}
          >
            <img
              // src={record?.downloadStatus ? lightDownload : darkDownload}
              src={
                record?.downloadStatus
                  ? darkDownload
                  : theme
                  ? darkLowIcon
                  : lightDownload
              }
              alt=""
            />
          </CS.ComButtonClient>
        </div>
      ),
    },
  ];
  const deleteComit = async (e) => {
    let url = Course.deletePolicy;
    const formValue = {
      ids: e,
    };
    try {
      const { data } = await axios.post(url, formValue);
      getPolicy();
      setOpens(false);
      setSelectedRowKeys([]);
      dispatch(setTableLength([]));
      setListId();
      setDeleteSuccess(true);
      setTimeout(() => {
        setDeleteSuccess(false);
      }, 2000);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        dispatch(setErrorPop(true));
        dispatch(setErrorPopMsg(error?.response?.data?.message));
      }
      console.log(error.response.data.message);
    }
  };
  const arr = [
    {
      _id: "65fbca432553e908e895a96f",
      clientId: "65fbc9472553e908e895a925",
      clientCourseId: "65fbca0f2553e908e895a968",
      userName: "TEST",
      email: "TEST@abc.com",
      mobileNumber: "1236547890",
      mobileNumberCountryCode: "+91",
      department: "TEST",
      reportingManager: "ABC",
      city: "XXYYZYY",
      designation: "HHAGGA",
      subEntity: "SHHSHA",
      active: 0,
      __v: 0,
      createdAt: "2024-03-21T05:48:51.210Z",
      updatedAt: "2024-03-21T05:48:51.210Z",
    },
    {
      _id: "65fd16d3d397422192b1ae6b",
      clientId: "65fbc9472553e908e895a925",
      clientCourseId: "65fbca0f2553e908e895a968",
      userName: "asad",
      email: "sad@gmail.com",
      mobileNumber: "2121212121",
      mobileNumberCountryCode: "+91",
      department: "asdsad",
      reportingManager: "asdsad",
      city: "asdsa",
      designation: "sadsada",
      subEntity: "sdsasadsad",
      active: 0,
      __v: 0,
      createdAt: "2024-03-22T05:27:47.182Z",
      updatedAt: "2024-03-22T05:27:54.450Z",
    },
    {
      _id: "65fd16d3d397422192b1ae6c",
      clientId: "65fbc9472553e908e895a925",
      clientCourseId: "65fbca0f2553e908e895a968",
      userName: "adas",
      email: "asdsa@gmail.com",
      mobileNumber: "2132122222",
      mobileNumberCountryCode: "+91",
      department: "asd",
      reportingManager: "asds",
      city: "ad",
      designation: "asdsa",
      subEntity: "sadsa",
      active: 1,
      __v: 0,
      createdAt: "2024-03-22T05:27:47.182Z",
      updatedAt: "2024-03-26T13:11:46.418Z",
    },
    {
      _id: "65fd19abd397422192b1ae91",
      clientId: "65fbc9472553e908e895a925",
      clientCourseId: "65fbca0f2553e908e895a968",
      userName: "asd",
      email: "ko@123.com",
      mobileNumber: "1221212121",
      mobileNumberCountryCode: "+91",
      department: "65fa6da3a9f8a4b8bbeda4c9",
      reportingManager: "asd",
      city: "asd",
      designation: "asd",
      subEntity: "asdsd",
      active: 0,
      __v: 0,
      createdAt: "2024-03-22T05:39:55.464Z",
      updatedAt: "2024-03-22T05:45:46.772Z",
    },
    {
      _id: "65fd1b27d397422192b1aec0",
      clientId: "65fbc9472553e908e895a925",
      clientCourseId: "65fbca0f2553e908e895a968",
      userName: "as",
      email: "ads@gmail.com",
      mobileNumber: "2121212112",
      mobileNumberCountryCode: "+91",
      department: "65fa6d96a9f8a4b8bbeda4c4",
      reportingManager: "sadsa",
      city: "asdsa",
      designation: "65fa6dc583fab35754c9f6ac",
      subEntity: "asdsd",
      active: 0,
      __v: 0,
      createdAt: "2024-03-22T05:46:15.828Z",
      updatedAt: "2024-03-22T05:46:15.828Z",
    },
    {
      _id: "65fd1b56d397422192b1aec7",
      clientId: "65fbc9472553e908e895a925",
      clientCourseId: "65fbca0f2553e908e895a968",
      userName: "asdsa",
      email: "mo@gma.com",
      mobileNumber: "2121211221",
      mobileNumberCountryCode: "+91",
      department: "65fa6d96a9f8a4b8bbeda4c4",
      reportingManager: "sadas",
      city: "asd",
      designation: "65fa6dc583fab35754c9f6ac",
      subEntity: "sadsad",
      active: 1,
      __v: 0,
      createdAt: "2024-03-22T05:47:02.245Z",
      updatedAt: "2024-03-22T05:47:02.245Z",
    },
    {
      _id: "65fd1b56d397422192b1aec8",
      clientId: "65fbc9472553e908e895a925",
      clientCourseId: "65fbca0f2553e908e895a968",
      userName: "sad",
      email: "sad@gm.com",
      mobileNumber: "3232323232",
      mobileNumberCountryCode: "+91",
      department: "65fa6d96a9f8a4b8bbeda4c4",
      reportingManager: "sadsa",
      city: "sad",
      designation: "65fa6dc583fab35754c9f6ac",
      subEntity: "asds",
      active: 1,
      __v: 0,
      createdAt: "2024-03-22T05:47:02.245Z",
      updatedAt: "2024-03-22T05:47:02.245Z",
    },
  ];
  const handleChange = (e) => {
    setInputValue(e.target.value);
  };
  const CustomCheckbox = ({ checked, onChange }) => {
    return (
      <div onClick={() => onChange(!checked)}>
        {checked ? (
          <MS.CheckBoxs>
            <img
              src={theme ? darkcheckIcon : checkboxIcon}
              style={{ marginRight: "0" }}
              alt="Checked"
            />
          </MS.CheckBoxs> // Your custom checked icon
        ) : (
          <MS.CheckBoxs>
            <img
              src={theme ? darkCheckboxBaseIcon : checkboxBaseIcon}
              style={{ marginRight: "0" }}
              alt="Unchecked"
            />
          </MS.CheckBoxs> // Your custom unchecked icon
        )}
      </div>
    );
  };
  const handleCheckboxChange = (id, checked) => {
    let newSelectedRowKeys = [...selectedRowKeys];

    if (checked) {
      newSelectedRowKeys.push(id);
    } else {
      newSelectedRowKeys = newSelectedRowKeys.filter((key) => key !== id);
    }

    console.log(newSelectedRowKeys); // Debugging line
    setSelectedRowKeys(newSelectedRowKeys);
    dispatch(setTableLength(newSelectedRowKeys));
  };

  const rowSelection = {
    getCheckboxProps: (record) => ({
      id: record._id, // Assuming each record has a unique _id
    }),
    renderCell: (checked, record, index, originNode) => (
      <CustomCheckbox
        checked={checked}
        onChange={(newChecked) => handleCheckboxChange(record._id, newChecked)}
      />
    ),
    selectedRowKeys, // Important to bind the selectedRowKeys state here
    onChange: (newSelectedRowKeys, selectedRows) => {
      setSelectedRowKeys(newSelectedRowKeys); // Update the state when selection changes
      dispatch(setTableLength(newSelectedRowKeys));
    },
  };

  const getPolicy = async () => {
    setFeildLoading(true);
    let url = `${Course.getPolicy}${courseId}?search=${inputValue}&page=${currentPage}&limit=10`;
    // const formValue = {
    //   limit: pageLimit,
    //   page: currentPage,
    //   _id: courseId,
    //   languageId: "66262d5fac4634a8113234ae",
    // };
    // inputValue && (formValue["search"] = inputValue);
    try {
      const { data } = await axios.get(url);
      if (data?.data) {
        setUserList(data.data?.docs);
      } else {
        setUserList([]);
      }
      setFeildLoading(false);
      setTotalCount(data.data?.totalDocs);
    } catch (error) {
      setFeildLoading(false);
      console.log(error.response.data.message);
    }
  };

  useEffect(() => {
    if (currentPage) {
      getPolicy();
    }
  }, [currentPage, inputValue]);

  const handleClosePolicy = (e) => {
    setIsOpenPolicy(e);
  };
  const handleCloses = () => {
    setOpens(false);
    setDeleteSuccess(false);
  };

  console.log(policyUrl, "policyUrl policyUrl");
  return (
    <>
      <CDS.DetailContainer>
        {/* <CourseView
          theme={theme}
          courseId={courseId}
          navigate={navigate}
          localState={localState}
        /> */}
        <CDS.DetailCon themeColor={theme}>
          <CDS.DetailInnerCon>
            <MS.ManageLearnerBox style={{ margin: 0 }}>
              <MS.FormContainerLearner themeColor={theme}>
                <MS.TotalCountRow>
                  <MS.ManageCommPolicy style={{ gap: "0.8rem" }}>
                    <MS.SearchBoxPolicy themeColor={theme}>
                      <img src={searchIcon} alt="" />
                      <input
                        type="text"
                        placeholder={selectLangJson.search_document}
                        onChange={handleChange}
                      />
                    </MS.SearchBoxPolicy>
                  </MS.ManageCommPolicy>
                  <MS.ManageCommPolicy style={{ gap: "0.8rem" }}>
                    <MS.AddCommonButtonPolicy
                      onClick={() =>
                        navigate("/client-admin/courses-manage/add-policy")
                      }
                    >
                      <img src={plusIcon} alt="" />
                      <span>{selectLangJson.upload_document}</span>
                    </MS.AddCommonButtonPolicy>
                  </MS.ManageCommPolicy>
                </MS.TotalCountRow>
                {userList?.length > 0 ? (
                  <SEC.ParentTable>
                    <SEC.TableBoxPolicy
                      checkCon={selectedRowKeys?.length === userList?.length}
                      themeColor={theme}
                    >
                      <Table
                        rowKey={(record) => record._id}
                        style={{
                          width: "100%",
                          minHeight: "500px",
                        }}
                        rowSelection={rowSelection}
                        columns={policyColumns}
                        className={
                          theme
                            ? "dark-table border-border"
                            : "light-table border-border"
                        }
                        dataSource={removeDuplicatesById(userList)}
                        scroll={{ x: true }}
                        pagination={{
                          current: currentPage,
                          pageSize: pageLimitLearner,
                          total: totalCountLearner,
                          onChange: (page) => {
                            console.log(page);
                            setCurrentPageLearner(page);
                          },
                          // You can use this property to customize the rendered pagination component
                          itemRender: (current, type, originalElement) => {
                            if (type === "prev") {
                              return (
                                <SEC.PagiButton themeColor={theme}>
                                  <img
                                    src={false ? lightLeft : darkArrow}
                                    style={{ marginRight: "8px" }}
                                    alt=""
                                  />{" "}
                                  <p>{selectLangJson.previous}</p>
                                </SEC.PagiButton>
                              );
                            }
                            if (type === "next") {
                              return (
                                <SEC.PagiButtonNext themeColor={theme}>
                                  <p>{selectLangJson.next}</p>
                                  <img
                                    src={false ? lighRight : darkRight}
                                    style={{ marginLeft: "8px" }}
                                    alt=""
                                  />
                                </SEC.PagiButtonNext>
                              );
                            }
                            return originalElement;
                          },
                        }}
                      />
                    </SEC.TableBoxPolicy>
                    {userList?.map((val, ind) => {
                      const isChecked = selectedRowKeys.includes(val._id);
                      return (
                        <SEC.TableCardPolicy themeColor={theme} key={ind}>
                          <SEC.TableTitleRow>
                            {selectedRowKeys?.length > 0 ? (
                              <SEC.CheckRowBox>
                                <CustomCheckbox
                                  checked={isChecked}
                                  onChange={(newChecked) =>
                                    handleCheckboxChange(val._id, newChecked)
                                  }
                                />
                              </SEC.CheckRowBox>
                            ) : (
                              ""
                            )}
                            <SEC.TitleRowFirst
                              wiDth={
                                selectedRowKeys?.length > 0 ? "85%" : "90%"
                              }
                              style={{ flexDirection: "row" }}
                            >
                              <SEC.BoxesContainer>
                                <SEC.FirstRowBox>
                                  <SEC.TitleRowInnerFirst>
                                    <SEC.TitleRowFirstTitle
                                      style={{ fontWeight: "500" }}
                                    >
                                      {val.userName}
                                    </SEC.TitleRowFirstTitle>
                                    <SEC.TitleRowFirstDateBlack>
                                      {val.email}
                                    </SEC.TitleRowFirstDateBlack>
                                  </SEC.TitleRowInnerFirst>
                                  <CS.StatusButtons
                                    themeColor={theme}
                                    isActive={val.active === 1 ? true : false}
                                  >
                                    {val.active === 0
                                      ? selectLangJson.InActive
                                      : selectLangJson.active}
                                  </CS.StatusButtons>
                                </SEC.FirstRowBox>
                                <SEC.FirstRowBox>
                                  <SEC.TitleRowInnerFirst>
                                    <SEC.TitleRowFirstDateBlack
                                      style={{ fontWeight: "500" }}
                                    >
                                      {selectLangJson.mobile_no}
                                    </SEC.TitleRowFirstDateBlack>
                                    <SEC.TitleRowFirstDescGrey>
                                      {val.mobileNumberCountryCode}{" "}
                                      {val.mobileNumber}
                                    </SEC.TitleRowFirstDescGrey>
                                  </SEC.TitleRowInnerFirst>
                                  <SEC.TitleRowInnerFirst>
                                    <SEC.TitleRowFirstDateBlack
                                      style={{ fontWeight: "500" }}
                                    >
                                      {selectLangJson.last_login}
                                    </SEC.TitleRowFirstDateBlack>
                                    <SEC.TitleRowFirstDescGrey>
                                      {moment(val.createdAt).format("ll")}
                                    </SEC.TitleRowFirstDescGrey>
                                  </SEC.TitleRowInnerFirst>
                                </SEC.FirstRowBox>
                                <SEC.FirstRowBox>
                                  <SEC.TitleRowInnerFirst>
                                    <SEC.TitleRowFirstDateBlack
                                      style={{ fontWeight: "500" }}
                                    >
                                      {selectLangJson.department}
                                    </SEC.TitleRowFirstDateBlack>
                                    <SEC.TitleRowFirstDescGrey>
                                      {val.department}
                                    </SEC.TitleRowFirstDescGrey>
                                  </SEC.TitleRowInnerFirst>
                                  <SEC.TitleRowInnerFirst>
                                    <SEC.TitleRowFirstDateBlack
                                      style={{ fontWeight: "500" }}
                                    >
                                      {selectLangJson.reporting_manager}
                                    </SEC.TitleRowFirstDateBlack>
                                    <SEC.TitleRowFirstDescGrey>
                                      {val.reportingManager}
                                    </SEC.TitleRowFirstDescGrey>
                                  </SEC.TitleRowInnerFirst>
                                </SEC.FirstRowBox>
                              </SEC.BoxesContainer>
                            </SEC.TitleRowFirst>
                            <SEC.TitleRowSecond style={{ width: "2%" }}>
                              <div
                                className="dot-button"
                                onClick={() => {
                                  setDropdownOpen(
                                    dropdownOpen === ind ? false : ind
                                  );
                                }}
                              >
                                <img src={dotImage} alt="" />
                              </div>
                              <SEC.Message
                                isHeight={"105px"}
                                dropdownOpen={dropdownOpen === ind}
                              >
                                {isLoading ? (
                                  ""
                                ) : (
                                  <SEC.MenuRow
                                    onClick={() => {
                                      setSelectedRowKeys([
                                        ...selectedRowKeys,
                                        val._id,
                                      ]);
                                      dispatch(
                                        setTableLength([
                                          ...selectedRowKeys,
                                          val._id,
                                        ])
                                      );

                                      setDropdownOpen(false);
                                    }}
                                  >
                                    <img src={delIcon} alt="" />{" "}
                                    {selectLangJson.select}
                                  </SEC.MenuRow>
                                )}
                                {
                                  <SEC.MenuRow>
                                    <img src={editIcon} alt="" />{" "}
                                    {selectLangJson.edit}
                                  </SEC.MenuRow>
                                }

                                {isLoading ? (
                                  ""
                                ) : (
                                  <SEC.MenuRow
                                    onClick={() => {
                                      if (val.deletePopupStatus) {
                                        setOpen(true);
                                        setDropdownOpen(false);
                                      } else {
                                        setOpens(true);
                                        setListId(val._id);
                                        setDropdownOpen(false);
                                      }
                                    }}
                                  >
                                    <img src={delIcon} alt="" />
                                    {selectLangJson.delete}
                                  </SEC.MenuRow>
                                )}
                              </SEC.Message>
                            </SEC.TitleRowSecond>
                          </SEC.TableTitleRow>
                          <SEC.TableTitleRowDusra></SEC.TableTitleRowDusra>
                        </SEC.TableCardPolicy>
                      );
                    })}
                  </SEC.ParentTable>
                ) : inputValue?.length > 0 ? (
                  <NoTableFoundSearch navigate={navigate} title={"Policy"} />
                ) : (
                  ""
                )}
                {selectedRowKeys.length > 0 ? (
                  <TS.DeleteRow>
                    <TS.DeleteInner themeColor={theme}>
                      <TS.SelectedText paDD={selectedRowKeys.length > 9}>
                        <div className="roundTxt">
                          {selectedRowKeys?.length}
                        </div>{" "}
                        {selectLangJson.selected}
                      </TS.SelectedText>
                      <TS.DeleteButton onClick={() => setOpens(true)}>
                        <img src={binTable} alt="" />
                        {selectLangJson.delete}
                      </TS.DeleteButton>
                    </TS.DeleteInner>
                  </TS.DeleteRow>
                ) : (
                  ""
                )}
              </MS.FormContainerLearner>
            </MS.ManageLearnerBox>
          </CDS.DetailInnerCon>
        </CDS.DetailCon>
      </CDS.DetailContainer>
      <ModalPolicy
        maxWidth={"1300px"}
        isOpen={isOpenPolicy}
        // isClose={handleClose}
        backColor={colorProvider.darkBlue}
        component={
          <MSPOP.LeaderContainerModal themeColor={theme}>
            <MSPOP.LeaderHeader themeColor={theme}>
              <MSPOP.HeaderBox themeColor={theme}>
                <p className="head-title">{selectLangJson.policy_document}</p>
              </MSPOP.HeaderBox>
              <MSPOP.CrossButton
                onClick={() => {
                  handleClosePolicy();
                  setpolicyStatus(false);
                }}
              >
                <img src={crossIcon} alt="" />
              </MSPOP.CrossButton>
            </MSPOP.LeaderHeader>
            <MSPOP.ViewCon>
              {policyUrl ? (
                policyUrl?.includes(".pdf") ? (
                  <PdfView policyUrl={policyUrl} />
                ) : (
                  <DocViewer fileUrl={policyUrl} />
                )
              ) : (
                ""
              )}
            </MSPOP.ViewCon>
          </MSPOP.LeaderContainerModal>
        }
      />
      <PopUp
        isOpen={opens}
        onClose={handleCloses}
        maxWidth="500px"
        title={
          <>
            <SEC.WarnIconBox>
              <img src={warnIcon} alt="danger" />
            </SEC.WarnIconBox>
          </>
        }
        content={
          <>
            <SEC.InnerSection themeColor={theme}>
              <SEC.Title style={{ textAlign: "center" }} themeColor={theme}>
                {selectLangJson.are_you_sure}!
              </SEC.Title>
              {/* <SEC.TitleDesc themeColor={theme}>
                (Category contains associated content or)
              </SEC.TitleDesc> */}
              <SEC.Subtitle style={{ textAlign: "center" }} themeColor={theme}>
                {
                  selectLangJson.deleting_this_policy_will_remove_all_associated_data_and_cannot_be_reversed
                }
              </SEC.Subtitle>
              <SEC.BtnGroup>
                <SEC.ComButton
                  themeColor={theme}
                  onClick={() => setOpens(false)}
                  type="button"
                >
                  {selectLangJson.cancel}
                </SEC.ComButton>
                <SEC.ComButton
                  themeColor={theme}
                  color={"#FFFFFF"}
                  borderColor={"#B3B9C6"}
                  backColor={"#E0271A"}
                  onClick={() => {
                    deleteComit(
                      selectedRowKeys.length > 0 ? selectedRowKeys : [listId]
                    );
                  }}
                >
                  {selectLangJson.delete}
                </SEC.ComButton>
              </SEC.BtnGroup>
            </SEC.InnerSection>
          </>
        }
      />
      <ModalSuccess
        isOpen={deleteSuccess}
        onClose={handleCloses}
        maxWidth="400px"
        title={
          <>
            <SEC.SuccessIconBoxThree>
              <img src={theme ? successModal : successModal} alt="danger" />
            </SEC.SuccessIconBoxThree>
          </>
        }
        content={
          <SEC.InnerSectionSuccessTwo>
            <SEC.TitleSuccess themeColor={theme}>Great!</SEC.TitleSuccess>
            {/* <SEC.TitleDesc themeColor={theme}>
                (Category contains associated content or) 
              </SEC.TitleDesc> */}
            <SEC.Subtitle themeColor={theme}>
              Deleted successfully.
            </SEC.Subtitle>
            <SEC.GreenContinue
              onClick={() => {
                handleCloses();
              }}
            >
              OK
            </SEC.GreenContinue>
          </SEC.InnerSectionSuccessTwo>
        }
      />
    </>
  );
};

export default CourseDetail;
