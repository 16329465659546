import React from "react";
import * as UE from "../styles/UserElement";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useSelector } from "react-redux";

const UploadLoader = ({ icon, upPercentage, name, size }) => {
  const theme = useSelector((state) => state?.isDark);
  const selectLangJson = useSelector((state) => state?.selectLangJson);
  return (
    <UE.FileRow>
      <UE.FileBox themeColor={theme} progressMo={upPercentage}>
        <img src={icon} alt="" />
        <UE.ContentRow>
          <UE.FileContentBox themeColor={theme}>
            <div className="head" themeColor={theme}>
              {name}
            </div>
            <div className="desc">
              {size} - {upPercentage}% {selectLangJson?.uploaded}
            </div>
          </UE.FileContentBox>
          <UE.CircleBox>
            <CircularProgressbar
              value={upPercentage}
              strokeWidth={"14"}
              styles={buildStyles({
                strokeLinecap: "butt",
                pathTransitionDuration: 0.5,
                pathColor: `#17B26A`,
              })}
            />
          </UE.CircleBox>
        </UE.ContentRow>
      </UE.FileBox>
    </UE.FileRow>
  );
};

export default UploadLoader;
