// global regex
const noHtmlRegex = /<\/?[^>]+(>|$)/g;
const onlyAlphbetRegex = /^[a-zA-Z ]*$/;
const regNum = /[^a-zA-Z0-9 ]/;
const numberOnly = /^\d+$/;
var phoneRegex = /^[0-9]+$/;
var numFeild = /^[+-]?\d+$/;
// var passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{7,}$/;
var passwordRegex =
  /^(?=.*[!@#$%^&*()_+\-=\[\]{}|;:,.<>?])(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[^\s]{8,32}$/;
const urlRegex = /^(https?):\/\/[^\s$.?#].[^\s]*$/;

let invalidText = "Character limit exceeded. Limit: 50 characters";
let feildCannot = "Field cannot be left blank";
// const { dictionary } = useContext(LanguageContext);
// console.log(messages);
export const loginValidator = (values) => {
  let errors = {};
  if (!values.email) {
    errors.email = "This is an error message.";
  } else if (checkEmail(values.email)) {
    errors.email = "Invalid Email";
  }
  if (!values.password) {
    errors.password = "This is an error message.";
  }
  return errors;
};
export const forgotValidator = (values) => {
  let errors = {};
  if (!values.email) {
    errors.email = "Enter Email";
  } else if (checkEmail(values.email)) {
    errors.email = "Invalid Email";
  }
  return errors;
};
export const resetValidator = (values, lang) => {
  let errors = {};
  if (!values.password) {
    errors.password = "Enter Password";
  } else if (values.password.length < 8) {
    errors.password = "Invalid Password";
  } else if (!passwordRegex.test(values.password)) {
    errors.password = "Invalid Password";
  }
  if (!values.confirm_password) {
    errors.confirm_password = "Enter Confirm Password";
  } else if (values.password !== values.confirm_password) {
    errors.confirm_password = "Password does not match";
  }
  return errors;
};
export const signUpValidator = (values) => {
  let errors = {};
  if (!values.email) {
    errors.email = "Enter Email";
  } else if (checkEmail(values.email)) {
    errors.email = "Invalid Email";
  }
  if (!values.password) {
    errors.password = "Enter Password";
  } else if (values.password.length < 8) {
    errors.password = "Password Should be 8 characters";
  }
  if (!values.confirm_password) {
    errors.confirm_password = "Enter Confirm Password";
  } else if (values.password !== values.confirm_password) {
    errors.confirm_password = "Password doesnot match with password";
  }
  return errors;
};
export const otpValidator = (values) => {
  let errors = {};
  if (!values.otp) {
    errors.otp = "Enter OTP";
  } else if (values.otp.length < 4) {
    errors.otp = "Invalid OTP";
  }
  return errors;
};
export const getOtpValidator = (values) => {
  let errors = {};
  if (!values.mobile_number) {
    errors.phone = "Enter Mobile Number";
  } else if (!phoneRegex.test(values.mobile_number)) {
    errors.phone = "Please enter a valid mobile number.";
  } else if (values.mobile_number.length < 4) {
    errors.phone = "Please enter a valid mobile number.";
  }
  return errors;
};
export const batchValidator = (values) => {
  let errors = {};
  if (!values.value) {
    errors.value = feildCannot;
  }
  return errors;
};
const checkEmail = (value) => {
  if (
    !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    )
  ) {
    return true;
  } else if (
    value.includes('"') ||
    value.includes("'") ||
    value.includes(",") ||
    value.includes(" ")
  ) {
    return true;
  } else {
    return false;
  }
};

export const formValidator = (values) => {
  let errors = {};

  // Ensure `data` exists and is an array
  if (values.data && Array.isArray(values.data)) {
    // Temporary object to hold questions' errors
    let questionsErrors = [];

    values.data.forEach((question, qIndex) => {
      // Check if the question has options and iterate
      if (question.options && Array.isArray(question.options)) {
        // Find if any option is marked as the correct answer
        const hasCorrectAnswer = question.options.some(
          (option) => option.isAnswer
        );

        if (!hasCorrectAnswer) {
          // If no correct answer is found, record an error for this question
          questionsErrors[qIndex] = {
            options:
              "At least one option must be marked as the correct answer.",
          };
        }
      }
    });

    // If there are any questions errors, add them to the errors object
    if (questionsErrors.length > 0) {
      errors.data = questionsErrors;
    }
  }

  return errors;
};
export const learnerClientValidator = (values) => {
  console.log(values, "learnerClientValidator");
  let errors = {};

  values.data.forEach((item, index) => {
    let itemErrors = {};

    // Validation logic for each item
    if (!item.name) {
      itemErrors.name = "Field cannot be left blank.";
    }
    if (item.name?.length > 50) {
      itemErrors.name = "Name is too long.";
    }
    if (!item.email) {
      itemErrors.email = feildCannot;
    } else if (checkEmail(item.email)) {
      itemErrors.email = "Invalid email format.";
    }
    if (item.mobileNumber && item.mobileNumber?.toString()?.length > 10) {
      itemErrors.mobileNumber = "Invalid mobile number.";
    }
    if (Object.keys(itemErrors).length > 0) {
      if (!errors.data) {
        errors.data = [];
      }
      errors.data[index] = itemErrors;
    }
  });
  return errors;
};
export const policyClientValidator = (values) => {
  console.log(values, "policyClientValidator");
  let errors = {};

  if (!values.title) {
    errors.title = feildCannot;
  }
  if (values.title?.length > 50) {
    errors.title = "Character limit exceeded. Limit: 50 characters";
  }
  if (values.documents?.length === 0) {
    errors.documents = feildCannot;
  }

  return errors;
};

export const notiValidator = (values) => {
  let errors = {};
  if (!values.title) {
    errors.title = feildCannot;
  }
  if (values.userName?.length > 50) {
    errors.userName = invalidText;
  }
  if (!values.type) {
    errors.type = feildCannot;
  }
  if (!values.body) {
    errors.body = feildCannot;
  }
  return errors;
};

export const passwordValidator = (values) => {
  let errors = {};
  if (!values.oldPassword) {
    errors.oldPassword = feildCannot;
  }
  // if (!values.newPassword) {
  //   errors.newPassword = "Enter Password";
  // } else if (values.newPassword.length < 8) {
  //   errors.newPassword = "Invalid Password";
  // } else if (!passwordRegex.test(values.newPassword)) {
  //   errors.newPassword = "Invalid Password";
  // }
  // if (!values.confirmPassword) {
  //   errors.confirmPassword = "Enter Confirm Password";
  // } else if (values.newPassword !== values.confirmPassword) {
  //   errors.confirmPassword = "Password does not match";
  // }

  if (!values.newPassword) {
    errors.newPassword = feildCannot;
  } else if (values.newPassword.length < 8) {
    errors.newPassword =
      "Password must contain 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character";
  } else if (!passwordRegex.test(values.newPassword)) {
    errors.newPassword = "Invalid Password";
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = feildCannot;
  } else if (values.newPassword !== values.confirmPassword) {
    errors.confirmPassword = "Password does not match";
  }

  return errors;
};
export const comiteClientValidator = (values) => {
  let errors = {};
  if (!values.committeeName) {
    errors.committeeName = feildCannot;
  } else if (values.committeeName?.length > 50) {
    errors.committeeName = invalidText;
  }
  if (!values.city) {
    errors.city = feildCannot;
  } else if (values.city?.length > 50) {
    errors.city = invalidText;
  }
  return errors;
};
export const memberValidator = (values) => {
  let errors = {};
  if (!values.name) {
    errors.name = feildCannot;
  } else if (values.name?.length > 50) {
    errors.name = invalidText;
  }
  if (!values.email) {
    errors.email = feildCannot;
  } else if (checkEmail(values.email)) {
    errors.email = "Invalid Email";
  }
  if (values.internal) {
  } else if (values.external) {
    if (!values.mobileNumber) {
      errors.mobileNumber = feildCannot;
    }
    if (values.mobileNumber?.length > 10) {
      errors.mobileNumber = "Invalid Mobile Number";
    }
    if (!values.city) {
      errors.city = feildCannot;
    }
    if (!values.position) {
      errors.position = feildCannot;
    }
  }

  console.log(errors, "errors errors");
  return errors;
};
export const feedbackValidator = (values) => {
  let errors = {};

  if (values.markAsMandtory?.name) {
    if (!values.name) {
      errors.name = feildCannot;
    }
  }
  if (values.markAsMandtory?.email) {
    if (!values.email) {
      errors.email = feildCannot;
    }
  }
  if (values.markAsMandtory?.organizationName) {
    if (!values.organizationName) {
      errors.organizationName = feildCannot;
    }
  }

  values.questions?.forEach((question, questionIndex) => {
    let questionErrors = {};
    let questionValuesErrors = [];

    switch (question.questionType) {
      case "single_choice":
        // Ensure there is a selected option for single choice
        if (question?.isMandtory) {
          if (!question.selectedOption) {
            questionErrors.selectedOption = "Please select an option";
          }
        }
        break;
      case "multiple_choice":
        // Ensure there are selected options for multiple choice
        if (question?.isMandtory) {
          if (
            !question.selectedOptions ||
            question.selectedOptions?.length === 0
          ) {
            questionErrors.selectedOptions =
              "Please select at least one option";
          }
        }
        break;
      case "descriptive":
        // Validate descriptive field is not empty
        if (question?.isMandtory) {
          if (
            !question.selectedOption ||
            question.selectedOption?.trim() === ""
          ) {
            questionErrors.selectedOption = "Description cannot be blank";
          }
        }
        break;
      case "star":
        // Validate descriptive field is not empty
        if (question?.isMandtory) {
          if (!question.selectedOption) {
            questionErrors.selectedOption = "Please select";
          }
        }
        break;
      case "smiley_emojies":
        // Validate descriptive field is not empty
        if (question?.isMandtory) {
          if (!question.selectedOption) {
            questionErrors.selectedOption = "Please select";
          }
        }
        break;
      default:
        // Handle unexpected question types
        console.log(`Unhandled question type: ${question.questionType}`);
        break;
    }

    // Additional validation for option titles in choices
    question.questionValues?.forEach((option, optionIndex) => {
      let optionErrors = {};
      if (!option.title) {
        optionErrors.title = "Field cannot be left blank";
      }
      if (option.title?.length > 500) {
        optionErrors.title = "Character limit exceeded. Limit: 500 characters";
      }
      if (Object.keys(optionErrors)?.length > 0) {
        questionValuesErrors[optionIndex] = optionErrors;
      }
    });

    if (questionValuesErrors?.length > 0) {
      questionErrors.questionValues = questionValuesErrors;
    }

    // If there are any errors for the question, add them to the errors object.
    if (Object.keys(questionErrors)?.length > 0) {
      errors.questions = errors.questions || [];
      errors.questions[questionIndex] = questionErrors;
    }
  });

  console.log(errors, "errors errorsv");
  return errors;
};
export const EmailSendValidator = (values) => {
  console.log(values, "courseValidator");
  let errors = {};

  // if (values.users?.length === 0) {
  //   errors.users = feildCannot;
  // }
  if (!values.subject) {
    errors.subject = feildCannot;
  }
  if (!values.body) {
    errors.body = feildCannot;
  }
  return errors;
};
export const emailSendValidator = (values) => {
  console.log(values, "courseValidator");
  let errors = {};
  if (!values.from) {
    errors.from = feildCannot;
  }
  if (values.sendTo?.length === 0) {
    errors.sendTo = feildCannot;
  }
  if (!values.subject) {
    errors.subject = feildCannot;
  }
  if (!values.body) {
    errors.body = feildCannot;
  }
  return errors;
};
export const sheduleValidator = (values) => {
  let errors = {};
  if (!values.start_date) {
    errors.start_date = feildCannot;
  }
  if (!values.count) {
    errors.count = feildCannot;
  }
  if (!values.schedule_type) {
    errors.schedule_type = feildCannot;
  }
  if (values.schedule_type === "weekly") {
    if (!values.week_day) {
      errors.week_day = feildCannot;
    }
  }
  if (values.schedule_type === "monthly") {
    if (!values.monthly_type) {
      errors.monthly_type = "Select Monthly Type";
    } else if (values.monthly_type === "onDay") {
      if (!values.onDay) {
        errors.onDay = feildCannot;
      }
      if (values.onDay > 31) {
        errors.onDay = "Invalid On Day";
      }
    } else if (values.monthly_type === "custom") {
      if (!values.week_day) {
        errors.week_day = feildCannot;
      }
      if (!values.week_type) {
        errors.week_type = feildCannot;
      }
    }
  }
  if (values.schedule_type === "yearly") {
    if (!values.year_type) {
      errors.year_type = "Select Yearly Type";
    } else if (values.year_type === "custom") {
      if (!values.onDay) {
        errors.onDay = feildCannot;
      }
      if (!values.month) {
        errors.month = feildCannot;
      }
    } else if (values.year_type === "schedule") {
      if (!values.week_day) {
        errors.week_day = feildCannot;
      }
      if (!values.week_type) {
        errors.week_type = feildCannot;
      }
      if (!values.month_two) {
        errors.month_two = feildCannot;
      }
    }
  }
  if (!values.end_date) {
    errors.end_date = feildCannot;
  }
  return errors;
};