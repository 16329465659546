import dashLogo from "../../assets/images/sidebarIcon/lightDashboard.png";

import dashDark from "../../assets/images/sidebarIcon/dashDark.png";

export const SidebarData = [
  {
    title: "Dashboard",
    path: "/client-admin/dashboard",
    activeTab: ["/client-admin/dashboard"],
    icon: dashLogo,
    activeIcon: dashDark,
    moduleName: null,
  },
  {
    title: "Course",
    path: "/client-admin/courses",
    activeTab: ["/client-admin/courses"],
    icon: dashLogo,
    activeIcon: dashDark,
    moduleName: null,
  },
  {
    title: "Report",
    path: "/client-admin/reports/master-reports",
    activeTab: ["/client-admin/reports/master-reports"],
    icon: dashLogo,
    activeIcon: dashDark,
    moduleName: null,
  },
];
