export const USER_DATA = "USER_DATA";
export const IS_DARK = "IS_DARK";
export const IS_TOGGLE = "IS_TOGGLE";
export const ACCESS_MODULE = "ACCESS_MODULE";
export const SIDEBAR_TOGGLE = "SIDEBAR_TOGGLE";
export const DELETE_LENGTH = "DELETE_LENGTH";
export const SIDEBAR_TOGGLE_RESP = "SIDEBAR_TOGGLE_RESP";
export const IS_MODAL = "IS_MODAL";
export const ACCESS_TOKEN = "ACCESS_TOKEN";
export const COURSE_ID = "COURSE_ID";
export const CLIENT_ID = "CLIENT_ID";
export const CLIENT_VIEW_ID = "CLIENT_VIEW_ID";
export const ERROROBJ = "ERROROBJ";
export const ERRORPOPMSG = "ERRORPOPMSG";
export const LANGUAGEID = "LANGUAGEID";
export const SELECTLANG = "SELECTLANG";
export const SELECTLANGJSON = "SELECTLANGJSON";
export const LANGUAGES = "LANGUAGES";
export const PROFILEUPDATE = "PROFILEUPDATE";
export const PROFILEDATA = "PROFILEDATA";
export const PROFILEDETAIL = "PROFILEDETAIL";
export const ONBAORDING = "ONBAORDING";