import styled from "styled-components";

export const Commit = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const FirstRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #D7DAE0"};
  padding-top: 1rem;
`;
export const ComitContainer = styled.div`
  min-height: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 0;
`;
export const CommitRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    gap: 10px;
  }
`;
export const FirstBox = styled.div`
  width: 95%;
  display: flex;
  flex-direction: column;
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #D7DAE0"};
  border-radius: 8px;
  padding: 0.8rem 1rem;
  @media (max-width: 768px) {
    flex: 1;
    width: unset;
  }

  .title {
    font-size: 0.8rem;
    font-weight: 500;
    color: ${(props) => (props.themeColor ? "#CECFD2" : "#363A44")};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .desc {
    font-size: 0.7rem;
    font-weight: 400;
    color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 3px;
  }
`;
export const IconBox = styled.div`
  width: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-left: 1rem;

  img {
    height: 18px;
    width: 18px;
    object-fit: contain;
    cursor: pointer;
  }
`;
export const IconBoxCommitte = styled.div`
  width: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-left: 1rem;

  img {
    height: 18px;
    width: 18px;
    object-fit: contain;
    cursor: pointer;
  }
  @media (max-width: 768px) {
    width:30px;
  }
`;
export const IconBoxCommitteTwo = styled.div`
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-left: 1rem;

  img {
    height: 18px;
    width: 18px;
    object-fit: contain;
    cursor: pointer;
  }
  @media (max-width: 768px) {
    width:30px;
  }
`;
